import { createSlice } from '@reduxjs/toolkit';
import { setActiveUser, deleteToken } from '../../helpers/localstorage.helper';
// import { PURGE } from 'redux-persist';

const initialState = {
  logged: false,
  id: null,
  idPadre: null,
  email: null,
  keyDecrypt: null,
  name: null,
  surName: null,
  secondSurName: null,
  avatar: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      const {
        email,
        id,
        idPadre,
        nombre,
        keyDecrypt,
        primerApellido,
        segundoApellido,
        avatarBase64,
      } = action.payload;
      setActiveUser(id);
      state.logged = true;
      state.id = id;
      state.idPadre = idPadre;
      state.avatar = avatarBase64;
      state.name = nombre;
      state.surName = primerApellido;
      state.secondSurName = segundoApellido;
      state.keyDecrypt = keyDecrypt;
      state.email = email;
    },
    logout: (state) => {
      deleteToken();
      state.logged = false;
      state.id = null;
      state.idPadre = null;
      state.avatar = null;
      state.name = null;
      state.surName = null;
      state.secondSurName = null;
      state.keyDecrypt = null;
      state.email = null;
    },
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;

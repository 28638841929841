import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import TitleModule from './../core/TitleModule';
import DateFormatted from './../generic/DateFormatted';
import './../../styles/components/Tasks.scss';
import TaskItem from '../TaskItem';
import useNotifications from './../../hooks/useNotifications';
import Config from './../../config/general-config';
import NoContent from '../generic/NoContent';
import { useSelector, useDispatch } from 'react-redux';
import NotificationController from './../../controllers/notification.controller';
import { showLoader, hideLoader } from '../../stores/slices/loaderSlice';
import { getLocalDate, formatearFecha } from './../../utils/dates.util';
import { showToast, TYPE_ICON } from '../../helpers/sweetAlert.helper';
import notificacionesService from './../../services/notificaciones.service';
import SyncController from './../../controllers/sync.controller';
import SelectedTask from '../SelectedTask';

export default function Tasks() {
  const notifications = useNotifications([]);
  const [filteredTask, setFilteredTasks] = useState(notifications);
  const [activeTask, setActiveTask] = useState(null);
  const user = useSelector((state) => state.user);
  const studentId = useSelector((state) => state.student.id);
  const notificationController = useRef(
    new NotificationController(user.email, user.keyDecrypt)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let _tasks = notifications.filter(
      (task) => task.tipoNotificacion === Config.TYPE_NOTIFICATION.TASK
    );
    setActiveTask(_tasks[0]);
    setFilteredTasks(_tasks);
  }, [notifications]);

  const showDetailsTask = async (task) => {
    setActiveTask(task);
    if (task.read) {
      return;
    }
    dispatch(showLoader());
    try {
      const markAsReadOnServer = await notificacionesService.readNotifications(
        user.id,
        [
          {
            idNotificacion: task.idNotificacion,
            fechaLectura: formatearFecha(getLocalDate(), '/'),
            idAlumno: studentId,
          },
        ]
      );
      const markAsReadLocally = await notificationController.current.markAsRead(
        task.idNotificacion,
        user.id,
        studentId
      );
      if (!markAsReadLocally)
        throw new Error('La notificación no se ha podido marcar como leída');

      if (!markAsReadOnServer) {
        const syncController = new SyncController(user.email, user.keyDecrypt);
        await syncController.putAction({
          idAlumno: studentId,
          archivo: null,
          tipo: Config.SYNC_ACTIONS.MARK_AS_READ,
          idDocumento: null,
          idNotificacion: task.idNotificacion,
          idUsuario: user.id,
          mimeType: null,
          extraData: null,
        });
        showToast(
          'Sincronización pendiente',
          'La tarea se ha marcado como leída pero los cambios serán sincronizados posteriormente',
          TYPE_ICON.INFO
        );
      }
      task.read = true;
      setFilteredTasks([...filteredTask]);
    } catch (e) {
      showToast(
        'Error',
        'Ha ocurrido un error al marcar la tarea como leída. Intente nuevamente por favor',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <React.Fragment>
      <TitleModule title={'Tareas'} />
      <Container>
        <NoContent
          thereAreContent={filteredTask.length > 0}
          title="No existen tareas para mostrar"
          icon="fas fa-book"
        >
          <div className="tasks">
            <DateFormatted />
            {activeTask && <SelectedTask task={activeTask} />}
            <div className="tasks-container mb-3">
              {filteredTask.map((task) => (
                <TaskItem
                  task={task}
                  key={task.idNotificacion}
                  activeTask={showDetailsTask}
                  active={task.idNotificacion === activeTask?.idNotificacion}
                />
              ))}
            </div>
          </div>
        </NoContent>
      </Container>
    </React.Fragment>
  );
}

import SyncModel from './../models/sync.model';
import { b64ToFile, fileToB64 } from './../utils/utils';
import Config from './../config/general-config';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import studentService from './../services/student.service';
import digitalFileService from '../services/digitalFile.service';
import { formatearFecha, getLocalDate } from './../utils/dates.util';
import notificacionesService from '../services/notificaciones.service';

class SyncController {
  #model;
  #email;
  #secretKey;

  constructor(email, secretKey) {
    this.#model = new SyncModel(email, secretKey);
    this.#email = email;
    this.#secretKey = secretKey;
  }

  async putFileAction(action) {
    const archivoB64 = await fileToB64(action.archivo);
    action = { ...action, archivo: archivoB64 };
    return this.#model.putUniqueAction(action);
  }

  putAction(action) {
    return this.#model.putAction(action);
  }

  putUniqueAction(action) {
    return this.#model.putUniqueAction(action);
  }

  readActions() {
    return this.#model.readActions();
  }

  async syncActions() {
    const pendingActions = await this.readActions();
    if (pendingActions.length === 0) {
      return;
    }
    pendingActions.forEach(async (action) => {
      await this.#syncByAction(action);
    });
    showToast(
      'Sincronización completa',
      'Se han sincronizado todos los cambios pendientes al servidor',
      TYPE_ICON.SUCCESS
    );
    return;
  }

  async deleteActionSynchronized(id) {
    return this.#model.deleteActionSynchronized(id);
  }

  async #syncByAction(action) {
    const { tipo, id } = action;

    const SYNC_ACTIONS = {
      [Config.SYNC_ACTIONS.MARK_AS_READ]: async (action) => {
        const { idNotificacion, idUsuario, idAlumno } = action;
        await notificacionesService.readNotifications(idUsuario, [
          {
            idNotificacion,
            fechaLectura: formatearFecha(getLocalDate(), '/'),
            idAlumno,
          },
        ]);
      },
      [Config.SYNC_ACTIONS.UPLOAD_PHOTO]: async (action) => {
        const { idAlumno, archivo, mimeType } = action;
        const formData = new FormData();
        const file = b64ToFile(archivo, mimeType);
        formData.append('photo', file);
        await digitalFileService.uploadStudentPhoto(idAlumno, formData);
      },
      [Config.SYNC_ACTIONS.UPLOAD_DOC]: async (action) => {
        const { idAlumno, archivo, mimeType, idDocumento } = action;
        const formData = new FormData();
        const file = b64ToFile(archivo, mimeType);
        formData.append('doc', file);
        await digitalFileService.uploadDocument(
          idAlumno,
          idDocumento,
          formData
        );
      },
      [Config.SYNC_ACTIONS.UPDATE_SIZE_WEIGHT]: async (action) => {
        const {
          extraData: { size, weight },
          idAlumno,
        } = action;
        await studentService.updateSizeWeight(idAlumno, size, weight);
      },
    };
    if (SYNC_ACTIONS[tipo]) {
      await SYNC_ACTIONS[tipo](action);
      await this.deleteActionSynchronized(id);
    }
    return;
  }
}

export default SyncController;

import React from 'react';
import SVGIconFromText from '../generic/SVGIconFromText';
import './../../styles/components/SideMenuItem.scss';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleOpen } from '../../stores/slices/menuSlice';

export default function SideMenuItem({ item, active = false }) {
  const dispatch = useDispatch();
  return (
    <Link
      className={'side-menu-item cursor-pointer ' + (active ? 'active' : '')}
      to={item.href}
      onClick={() => {
        dispatch(toggleOpen());
      }}
    >
      <SVGIconFromText tagText={item.icon} />
      <span className="f-14px">{item.title}</span>
    </Link>
  );
}

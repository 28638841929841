import React from 'react';
import TitleModule from './../core/TitleModule';
import { Container } from 'react-bootstrap';
import './../../styles/components/OrderDetail.scss';
import TableProductsOrder from '../TableProductsOrder';
import OnlineContent from './../core/OnlineContent';

export default function OrderDetail() {
  return (
    <React.Fragment>
      <TitleModule title="Uniformes y útiles escolares" />
      <div className="order-detail h-100 pt-3">
        <Container>
          <h6 className="text-uppercase text-primary monserrat-bold-font text-center text-md-start px-2">
            Carrito de compras
          </h6>
        </Container>
        <div className="order-products-container mt-5">
          <div className="order-products mx-auto">
            <OnlineContent>
              <TableProductsOrder />
            </OnlineContent>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import React from 'react';
import '../styles/components/NormatecaDocumentsList.scss';
import { Col } from 'react-bootstrap';

export default function NormatecaDocumentsList({ files, handleClickFile }) {
  return (
    files &&
    files.map((file) => (
      <Col
        xs={12}
        lg={6}
        key={file.idArchivo}
        className={file.type.toString().toLowerCase() + '-document'}
      >
        <div
          onClick={() => {
            handleClickFile(file);
          }}
          className="normateca-document-item monserrat-font font-bold text-uppercase cursor-pointer"
        >
          {file.documento}
        </div>
      </Col>
    ))
  );
}

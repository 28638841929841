import Config from './../config/general-config';
import { getExtentionFromMimeType, calculateSizeOfFile } from '../utils/utils';
class Validators {
  static Email = (value, unicode = true) => {
    let regExp =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (unicode) {
      regExp =
        // eslint-disable-next-line
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    }
    return regExp.test(String(value).toLowerCase());
  };
  static Required = (value) => {
    if (value === 0) {
      return true;
    }
    if (!value) {
      return false;
    }
    return true;
  };

  static Size = (value) =>
    value >= Config.MIN_SIZE_M && value <= Config.MAX_SIZE_M;

  static Weight = (value) =>
    value >= Config.MIN_WEIGHT_KG && value <= Config.MAX_WEIGHT_KG;

  static TrimedString = (value) => value.toString().trim().length > 0;

  static FileSizeAndType = (file, size, types) => {
    if (!file || !size || !types) {
      return { isValid: false, detail: 'Todos los parámetros son requeridos' };
    }
    if (!types.includes(getExtentionFromMimeType(file.type))) {
      return {
        isValid: false,
        detail: 'El tipo de archivo seleccionado no es válido',
      };
    }
    if (calculateSizeOfFile(file.size, 'MB') > size) {
      return {
        isValid: false,
        detail: `El archivo seleccionado tiene un peso mayor a ${size} Mb`,
      };
    }
    return { isValid: true, detail: 'Archivo válido' };
  };
}
export default Validators;

import BaseModel from './base.model';

class DigitalFileModel extends BaseModel {
  constructor(email, secretKey) {
    super(email, secretKey, true);
  }

  async putDigitalFile(path, idStudent) {
    return this.db.transaction('rw', [this.db.digitalFile], async () => {
      await this.db.digitalFile.bulkPut(path);
    });
  }

  readDigitalFile(idStudent) {
    return this.db.digitalFile.where('idAlumno').equals(idStudent).toArray();
  }

  async readFile(idDocumento, idStudent) {
    const document = await this.db.digitalFile_files
      .where({ idAlumno: idStudent, idDocumento })
      .first();
    return document.file;
  }

  updateFile(data) {
    return this.db.digitalFile_files.put(data);
  }

  updateDataFile(idDocumento, idStudent) {
    return this.db.digitalFile
      .where({ idAlumno: idStudent, idDocumento })
      .modify({ archivo: { idArchivo: 'offline', estatus: 1 } }); // FAKE info to keep consistency
  }
}

export default DigitalFileModel;

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ConnectionIndicator from './ConnectionIndicator';
import { useDispatch } from 'react-redux';
import { updateActiveMenu } from '../../stores/slices/menuSlice';

export default function TitleModule({ title = '' }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateActiveMenu({ label: title, id: '' }));
  }, [title, dispatch]);

  return (
    title && (
      <Container className="mt-4">
        <Row>
          <Col xs={12} className="d-none d-md-flex align-items-center">
            <div className="monserrat-bold-font font-bold f-25px text-primary flex-grow-1 text-uppercase">
              {title}
            </div>
            <ConnectionIndicator />
          </Col>
        </Row>
      </Container>
    )
  );
}

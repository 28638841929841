import React from 'react';
import notificationsIcon from '../assets/imgs/notification.svg';
import './../styles/components/Notifications.scss';
import { Link } from 'react-router-dom';
import NotificationsNotReaded from './NotificationsNotReaded';
import Config from '../config/general-config';

export default function Notifications() {
  return (
    <Link className="notifications" to={'/avisos-notificaciones'}>
      <div className="notifications-icon-container header-icon-container">
        <img
          src={notificationsIcon}
          alt="Notificaciones"
          className="notifications-icon header-icon"
        />
      </div>
      <NotificationsNotReaded
        type={Config.TYPE_NOTIFICATION.NOTICE}
        style={{
          bottom: '-10px',
          left: '-10px',
        }}
      />
    </Link>
  );
}

import React from 'react';
import { ReactComponent as UserDefaultAvatar } from './../assets/imgs/user_default.svg';
import './../styles/components/StudentItem.scss';
import { useDispatch } from 'react-redux';
import { updateActiveStudent } from '../stores/slices/studentSlice';

export default function StudentItem({ student, active = false, _click }) {
  const dispatch = useDispatch();

  return (
    student && (
      <div
        className={'student-item cursor-pointer ' + (active ? 'active' : '')}
        onClick={() => {
          dispatch(updateActiveStudent(student));
          _click();
        }}
      >
        <div className="student-item-container d-flex align-items-center">
          <div className="student-image-container">
            {student.fotoBase64 ? (
              <img
                src={student.fotoBase64}
                alt={student.nombre ?? 'Imagen del usuario'}
                className="student-image"
              />
            ) : (
              <UserDefaultAvatar />
            )}
          </div>
          <div className="student-name-container flex-grow-1">
            <div className="student-name monserrat-bold-font f-12px text-primary">
              {`${student.nombre} ${student.primerApellido ?? ''} ${
                student.segundoApellido ?? ''
              }`}
            </div>
          </div>
        </div>
      </div>
    )
  );
}

import React from 'react';
import '../styles/components/ProcedureStatusItem.scss';
import { formatearFecha } from '../utils/dates.util';
import { Row, Col } from 'react-bootstrap';
import ProcedureStatusActions from './ProcedureStatusActions';

export default function ProcedureStatusItem({ item = {}, isHeader }) {
  const {
    fechaSolicitud: date,
    servicio: type,
    estatusSolicitud: status,
    nombreAlumno: name,
  } = item;

  return (
    <div className="procedure-status-item">
      <Row className="procedure-status-row">
        <Col xs={3} className="col-status-date">
          {isHeader ? (
            <span className="col-header">Fecha</span>
          ) : (
            <span>{formatearFecha(date)}</span>
          )}
        </Col>
        <Col xs={3} className="col-status-type">
          {isHeader ? (
            <span className="col-header">Trámite</span>
          ) : (
            <span>
              <span>{type}</span>
              <small className="d-block font-italic f-10px text-orange font-bold">
                {name}
              </small>
            </span>
          )}
        </Col>
        <Col xs={3} className="col-status-detail">
          {isHeader ? (
            <span className="col-header">Estatus</span>
          ) : (
            <span>{status}</span>
          )}
        </Col>
        <Col xs={3} className="col-status-actions">
          {isHeader ? (
            <span className="col-header">Acción</span>
          ) : (
            <ProcedureStatusActions item={item}></ProcedureStatusActions>
          )}
        </Col>
      </Row>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import '../styles/components/SoftwareLink.scss';
import defaultLinkImg from '../assets/imgs/no_url.svg';
import fileService from '../services/file.service';

export default function SoftwareLink({ link }) {
  const { documento, enlace } = link;
  const [img, setImg] = useState(null);

  useEffect(() => {
    const fetchImg = async () => {
      const _img = await fileService.getDocument(link.idArchivo);
      setImg(_img);
    };
    fetchImg();
  }, [link]);

  return (
    enlace && (
      <div className="software-link scale-hover">
        <a
          className="software-link-container"
          href={enlace}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="software-link-title"></div>
          <div className="software-link-body">
            <img
              className="software-link-img"
              src={
                img
                  ? `data:${img.tipoArchivo};base64,${img.archivoBytes}`
                  : defaultLinkImg
              }
              alt={documento}
            />
          </div>
          <div className="software-link-footer"></div>
        </a>
      </div>
    )
  );
}

import React from 'react';
import { Container } from 'react-bootstrap';
import ProceduresList from '../ProceduresList';
import ProceduresStatus from '../ProceduresStatus';
import TitleModule from './../core/TitleModule';
import OnlineContent from './../core/OnlineContent';

export default function Procedures() {
  return (
    <React.Fragment>
      <TitleModule title={'Trámites en Línea'} />
      <Container>
        <OnlineContent>
          <div className="procedures">
            <div className="procedures-availables-container">
              <ProceduresList />
            </div>
            <div className="procedures-request-container">
              <ProceduresStatus />
            </div>
          </div>
        </OnlineContent>
      </Container>
    </React.Fragment>
  );
}

import React from 'react';
import '../styles/components/Procedure.scss';
import { Link } from 'react-router-dom';

export default function Procedure({ procedure }) {
  const {
    servicio: title,
    descripcion: description,
    idServicio: id,
  } = procedure;
  return (
    <Link
      className="procedure cursor-pointer"
      to={`/info-tramite/${id}`}
      as={'div'}
    >
      <div className="procedure-header text-center">
        <div className="procedure-icon"></div>
        <div className="procedure-title arvo-font text-primary">{title}</div>
      </div>
      <div className="procedure-body f-10px text-primary text-center">
        {description}
      </div>
      <div className="procedure-footer bg-orange text-center ">
        <span className="text-white verdana-font f-14px">Solicitar</span>
      </div>
    </Link>
  );
}

import React from 'react';
import './../../styles/components/Loader.scss';
import { useSelector } from 'react-redux';

export default function Loader() {
  const showLoader = useSelector((state) => state.loader.show);
  return (
    showLoader && (
      <div className="loader">
        <div className="loader-container">
          <i className="fa fa-circle-notch fa-spin fa-2x"></i>
        </div>
      </div>
    )
  );
}

import baseService from './base.service';
import Config from '../config/general-config';
import wsResponseHandler from '../handlers/wsResponse.hanlder';
import { getToken } from '../helpers/localstorage.helper';

const schoolarshipsService = {
  getDocuments: async (idSchool) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.SCHOOLARSHIPS_DOCS,
      {
        _idEscuela: idSchool,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  getRequierements: async (idType, idStudent) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.SCHOOLARSHIP_REQUIEREMENTS,
      {
        _idTipoBeca: idType,
        _idAlumno: idStudent,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  getTypes: async (idSchool, idStudent) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.SCHOOLARSHIPS_TYPES,
      {
        _idEscuela: idSchool,
        _idAlumno: idStudent,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  requestSchoolarship: async (idParent, idRequest, idStudent) => {
    const response = await baseService.postRequest(
      Config.ENDPOINTS.SCHOOLARSHIP_REQUEST,
      {
        _idTipoBeca: idRequest,
        _idPadre: idParent,
        _idAlumno: idStudent,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return wsResponseHandler(response);
  },
  uploadDocument: async (idRequest, idDocument, file) => {
    const response = await baseService.postRequest(
      Config.ENDPOINTS.UPLOAD_DOCUMENT_SCHOOLARSHIP,
      file,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data',
        },
        params: {
          _idSolicitud: idRequest,
          _idDocumento: idDocument,
        },
      }
    );
    return wsResponseHandler(response);
  },
};

export default schoolarshipsService;

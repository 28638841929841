const API_URL_BASE = process.env.REACT_APP_API_URL_BASE;
const Config = {
  production: false,
  version: '1.0 alpha',
  compilation: 20221115.1,
  captcha_characters: 6,
  captcha_key: process.env.REACT_APP_CAPTCHA_KEY,
  captcha_secret: process.env.REACT_APP_CAPTCHA_SECRET,
  PUBLIC_VAPID_KEY: process.env.REACT_APP_PUBLIC_VAPID_KEY,
  ENDPOINTS: {
    ATTACH_FILE_MESSAGE: `${API_URL_BASE}/adjuntoSeguimiento`,
    CREATE_PROCESS: `${API_URL_BASE}/registrarSolicitud`,
    DETAIL_PROCESS: `${API_URL_BASE}/solicitudTramite`,
    DIGITAL_FILE: `${API_URL_BASE}/ExpedienteDigital`,
    DOCS_MANAGMENT: `${API_URL_BASE}/escuela/gestionDocumentos`,
    EDUCATIONAL_PATH: `${API_URL_BASE}/trayectoriaAlumno`,
    EVALUATIONS: `${API_URL_BASE}/evaluacionesPeriodo`,
    GENERATE_ORDER: `${API_URL_BASE}/crearPedido`,
    GET_FILE: `${API_URL_BASE}/getArchivo`,
    GET_PROCESS: `${API_URL_BASE}/tramitesEscuela`,
    LOGIN: `${API_URL_BASE}/Login`,
    LIST_ORDERS: `${API_URL_BASE}/consultaPedidosPadre`,
    LIST_PRODUCTS: `${API_URL_BASE}/consultaArticulos`,
    MENU_BOARD: '/data/menu.json',
    NORMATECA: `${API_URL_BASE}/normateca`,
    NOTIFICATIONS: `${API_URL_BASE}/Notificaciones`,
    PROCESS_ON_PROGRESS: `${API_URL_BASE}/solicitudesTramite`,
    READ_NOTIFICATION: `${API_URL_BASE}/leerNotificacion`,
    RECAPTCHA_VALIDATE: `${API_URL_BASE}/validaCaptcha`,
    REQUEST_PROCESS_DETAIL: `${API_URL_BASE}/requisitosTramite`,
    SCHOOLARSHIPS_DOCS: `${API_URL_BASE}/becas`,
    SCHOOLARSHIP_REQUEST: `${API_URL_BASE}/solicitarBeca`,
    SCHOOLARSHIP_REQUIEREMENTS: `${API_URL_BASE}/requisitosBeca`,
    SCHOOLARSHIPS_TYPES: `${API_URL_BASE}/tiposBeca`,
    SUBSCRIBE_NOTIFICATION: `${API_URL_BASE}/suscribirNotificaciones`,
    TRACING_PROCESS: `${API_URL_BASE}/seguimientosSolicitud`,
    TRACK_PROCESS: `${API_URL_BASE}/agregarSeguimiento`,
    UPDATE_SIZE_WEIGHT: `${API_URL_BASE}/alumno/actualizaMedidas`,
    UPLOAD_DOCUMENT: `${API_URL_BASE}/ExpedienteDigital/cargarDocumento`,
    UPLOAD_DOCUMENT_PROCESS: `${API_URL_BASE}/cargarDocumentoSolicitud`,
    UPLOAD_DOCUMENT_SCHOOLARSHIP: `${API_URL_BASE}/cargarDocumentoSolicitudBeca`,
    VIEW_DOCUMENT: `${API_URL_BASE}/archivoExpedienteDigital`,
    VIEW_DOCUMENT_SCHOOLARSHIPS: `${API_URL_BASE}/documentoBeca`,
  },
  LOCALSTORAGE_KEYS: {
    JWT: 'sac_jwt',
    ACTIVE_USER: 'sac_au',
  },
  validImagesExtentions: '.png,.jpg,.jpeg',
  validDocumentsExtentions: '.pdf, .doc, .docx, .png, .jpg, .jpeg',
  MAX_IMG_SIZE_MB: 10,
  MAX_DOC_SIZE_MB: 1,
  TYPE_NOTIFICATION: {
    NOTICE: 'Aviso',
    REMAINDER: 'Recordatorio',
    TASK: 'Tarea grupal',
  },
  DELETE_NOTIFICATION_AFTER_DAYS: 30,
  SYNC_ACTIONS: {
    MARK_AS_READ: 'mark_as_read',
    UPLOAD_DOC: 'upload_doc',
    UPLOAD_PHOTO: 'upload_photo',
    UPDATE_SIZE_WEIGHT: 'update_size_weight',
  },
  FILE_STATUS: {
    NO_LOADED: 0,
    LOADED: 1,
    VALIDATED: 2,
    REJECTED: 3,
  },
  MIN_SIZE_M: 0.5,
  MAX_SIZE_M: 2.5,
  MIN_WEIGHT_KG: 10,
  MAX_WEIGHT_KG: 200,
  MOBILE_WIDTH: 768,
  GRADES_BY_LEVEL: {
    1: 3,
    2: 6,
    3: 3,
    4: 6,
  },
  PROCEDURES_STATUS: {
    REQUESTED: 1,
    FINISHED: 2,
    CANCELED: 3,
    CANCELED_BY_MANAGEMENT: 4,
    INCOMPLETE: 5,
    ACCEPTED: 6,
    REJECTED: 7,
  },
  EVALUATION_TYPE: {
    NUMERICA: 'NUMERICA',
    ABIERTA: 'ABIERTA',
    LITERAL: 'LITERAL',
  },
  SCHOOLAR_LEVELS: {
    PREESCOLAR: 1,
    PRIMARIA: 2,
    SECUNDARIA: 3,
    MEDIA_SUPERIOR: 4,
  },
};

export default Config;

import NotificationModel from './../models/notification.model';
import Config from '../config/general-config';

class NotificationController {
  #model;

  constructor(email, secretKey) {
    this.#model = new NotificationModel(email, secretKey);
  }

  addNotifications(notifications, idUser) {
    return this.#model.addNotifications(notifications, idUser);
  }

  readNotifications(idUser, idStudent) {
    return this.#model.readNotifications(idUser, idStudent);
  }

  markAsRead(idNotification, idUser, idStudent) {
    return this.#model.markAsRead(idNotification, idUser, idStudent);
  }

  deleteOldNotifications() {
    return this.#model.deleteOldNotifications();
  }

  notReaded(idUser, idStudent, type = Config.TYPE_NOTIFICATION.TASK) {
    return this.#model.notReaded(idUser, idStudent, type);
  }
}

export default NotificationController;

import Institution from '../models/institution.model';

class InstitutionController {
  #model;

  constructor(email, secretKey) {
    this.#model = new Institution(email, secretKey);
  }

  addInstitution(institution) {
    return this.#model.addInstitution({ ...institution, config: null });
  }

  putInstitution(institution) {
    return this.#model.putInstitution({ ...institution, config: null });
  }

  readInstitution(id) {
    return this.#model.readInstitution(id);
  }

  readInstitutions() {
    return this.#model.readInstitutions();
  }

  putSchoolarships(id, schoolarships) {
    schoolarships = { idInstitucion: id, ...schoolarships };
    return this.#model.putSchoolarships(schoolarships);
  }

  readSchoolarships(id) {
    return this.#model.readSchoolarships(id);
  }

  updateSchoolarShipFile(data) {
    return this.#model.updateSchoolarShipFile(data);
  }

  readSchoolarshipFile(id, idDocument) {
    return this.#model.readSchoolarshipFile(id, idDocument);
  }

  readConfig(id) {
    return this.#model.readConfig(id);
  }

  updateConfig(id, config) {
    return this.#model.updateConfig(id, config);
  }

  putNormateca(id, data) {
    data = data.map((item) => ({
      idInstitucion: id,
      ...item,
    }));
    return this.#model.putNormateca(data);
  }

  readNormateca(id) {
    return this.#model.readNormateca(id);
  }

  updateNormatecaFile(data) {
    return this.#model.updateNormatecaFile(data);
  }

  readNormatecaFile(id, idDocument) {
    return this.#model.readNormatecaFile(id, idDocument);
  }
}

export default InstitutionController;

import BaseModel from './base.model';
import {
  getLocalDate,
  formatearFecha,
  isBeforeXDays,
} from './../utils/dates.util';
import Config from './../config/general-config';

class NotificationModel extends BaseModel {
  constructor(email, secretKey) {
    super(email, secretKey, true);
  }

  async addNotifications(notifications) {
    return this.db.transaction('rw', [this.db.notifications], async () => {
      // TODO: Keep in local to consult, after an automatic process will erase older registers
      // await this.db.notifications.where('idUsuario').equals(idUser).delete();
      await this.db.notifications.bulkPut(notifications);
    });
  }

  readNotifications(idUser, idStudent) {
    return this.db.notifications
      .where({ idUsuario: idUser, idAlumno: idStudent })
      .toArray();
  }

  markAsRead(idNotification, idUser, idStudent) {
    return this.db.notifications
      .where({
        idNotificacion: idNotification,
        idUsuario: idUser,
        idAlumno: idStudent,
      })
      .modify({
        read: true,
        fechaLectura: formatearFecha(getLocalDate(), '/'),
      });
  }

  async deleteOldNotifications() {
    let itemsToDelete = await this.db.notifications.toArray();
    itemsToDelete = itemsToDelete.filter((item) =>
      isBeforeXDays(item.fecha, Config.DELETE_NOTIFICATION_AFTER_DAYS)
    );
    let keysToDelete = itemsToDelete.map((item) => item.idNotificacion);
    return await this.db.notifications.bulkDelete(keysToDelete);
  }

  async notReaded(idUser, idStudent, type) {
    const allowNotifications = await this.readNotifications(idUser, idStudent);
    if (type === Config.TYPE_NOTIFICATION.TASK) {
      return allowNotifications.filter(
        (notification) =>
          notification.tipoNotificacion === Config.TYPE_NOTIFICATION.TASK &&
          !notification.read
      ).length;
    }
    return allowNotifications.filter(
      (notification) =>
        notification.tipoNotificacion !== Config.TYPE_NOTIFICATION.TASK &&
        !notification.read
    ).length;
  }
}

export default NotificationModel;

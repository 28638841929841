import React from 'react';
import shoppingCar from './../assets/imgs/shopping-car.svg';
import { Link } from 'react-router-dom';
import './../styles/components/ShoppingCar.scss';
import { useSelector } from 'react-redux';

export default function ShoppingCar() {
  const products = useSelector((state) => state.cart.products);
  return (
    <div className="shopping-car">
      <div className="shopping-car-container d-flex">
        <div className="shopping-car-icon me-4">
          <img src={shoppingCar} alt="Carrito de compras" />
          <span className="badge bg-primary badge-shopping-car f-10px">
            {products && (products.length > 10 ? '10+' : products.length)}
          </span>
        </div>
        <div className="shopping-car-button">
          <Link to={'/carrito-compras'}>
            <button
              type="button"
              className="btn btn-sm bg-orange monserrat-bold-font text-white f-10px"
            >
              Ver carrito
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import './../styles/components/FormativeField.scss';
import { Badge } from 'react-bootstrap';
import Config from '../config/general-config';
import CustomTooltip from './generic/CustomTooltip';
import { isTruthy } from '../utils/utils';

export default function FormativeField({ field }) {
  const {
    matNombreCorto,
    matDescripcion,
    evaluacion,
    tipoMateria,
    tipoEvaluacion,
  } = field;
  const [showEvaluation, setShowEvaluation] = useState(false);

  return (
    <div className="formative-field pt-2 pb-3">
      <div className="formative-field-header mb-3">
        <div className="formative-field-type-container">
          <span
            className={`formative-field-type formative-field-${tipoMateria}`}
          >
            {tipoMateria}
          </span>
        </div>
        <div className="formative-field-title-container">
          <div className="formative-field-title text-left">
            <span className="monserrat-bold-font text-primary">
              {matNombreCorto}
            </span>
          </div>
        </div>
        <div className="formative-field-evaluation-container">
          {tipoEvaluacion === Config.EVALUATION_TYPE.ABIERTA ? (
            <CustomTooltip text="Mostrar detalles de la evaluación">
              <i
                className="fas fa-chevron-circle-down f-20px cursor-pointer text-primary"
                onClick={() => setShowEvaluation(!showEvaluation)}
              ></i>
            </CustomTooltip>
          ) : (
            <Badge bg="primary">{evaluacion}</Badge>
          )}
        </div>
      </div>
      <div className="formative-field-body">
        <div className="formative-field-description-container">
          <div className="formative-field-description">{matDescripcion}</div>
        </div>
        <div
          className={`formative-field-evaluation-open ${
            !showEvaluation ? 'hide' : ''
          }`}
        >
          {isTruthy(evaluacion) ? evaluacion : 'Sin evaluación'}
        </div>
      </div>
    </div>
  );
}

import baseService from './base.service';
import Config from '../config/general-config';
import { getToken } from '../helpers/localstorage.helper';
import wsResponseHandler from '../handlers/wsResponse.hanlder';

const ProceduresService = {
  addTrack: async (idRequest, details, idUser) => {
    const response = await baseService.postRequest(
      Config.ENDPOINTS.TRACK_PROCESS,
      {
        _idSolicitud: idRequest,
        _observaciones: details,
        _idUsuario: idUser,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response;
  },
  attachFileToMessage: async (idMessage, file) => {
    const response = await baseService.postRequest(
      `${Config.ENDPOINTS.ATTACH_FILE_MESSAGE}?_idSeguimiento=${idMessage}`,
      file,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return wsResponseHandler(response);
  },
  createProcedure: async (idParent, idRequest, idStudent) => {
    const response = await baseService.postRequest(
      Config.ENDPOINTS.CREATE_PROCESS,
      {
        _idServicio: idRequest,
        _idPadre: idParent,
        _idAlumno: idStudent,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return wsResponseHandler(response);
  },
  detailProcedure: async (idRequest, idParent) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.DETAIL_PROCESS,
      {
        _idSolicitud: idRequest,
        _idPadre: idParent,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  getProcedures: async (idInstitution) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.GET_PROCESS,
      {
        _idEscuela: idInstitution,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  getProceduresOnProgress: async (idParent) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.PROCESS_ON_PROGRESS,
      {
        _idPadre: idParent,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  getProcedureRequestDetail: async (id) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.REQUEST_PROCESS_DETAIL,
      {
        _idServicio: id,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  tracing: async (idRequest) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.TRACING_PROCESS,
      {
        _idSolicitud: idRequest,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  uploadDocument: async (idRequest, idDocument, file) => {
    const response = await baseService.postRequest(
      Config.ENDPOINTS.UPLOAD_DOCUMENT_PROCESS +
        `?_idSolicitud=${idRequest}&_idDocumento=${idDocument}`,
      file,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return wsResponseHandler(response);
  },
};

export default ProceduresService;

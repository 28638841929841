import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import InstitutionController from './../controllers/institution.controller';
import schoolarshipsService from './../services/schoolarships.service';

const useSchoolarShips = (initialValue) => {
  const { isOnline } = useSelector((state) => state.internet);
  const [schoolarships, setSchoolarships] = useState(initialValue ?? []);
  const institution = useSelector((state) => state.institution);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const institutionController = new InstitutionController(
      user.email,
      user.keyDecrypt
    );
    const fetchFiles = async () => {
      try {
        if (!institution) return;
        let files = await schoolarshipsService.getDocuments(institution.id);
        if (!files || typeof files === 'string') {
          dispatch(hideLoader());
          return;
        }

        await institutionController.putSchoolarships(institution.id, files);
        setSchoolarships(files);
      } catch (e) {
        showToast(
          'Error',
          'No se ha podido leer la información de becas',
          TYPE_ICON.ERROR
        );
      } finally {
        dispatch(hideLoader());
      }
    };

    dispatch(showLoader());
    if (isOnline) {
      fetchFiles();
    } else {
      institutionController
        .readSchoolarships(institution.id)
        .then((files) => {
          if (files.length === 0 && !isOnline) {
            showToast(
              'Conexión necesaria',
              'Para llevar a cabo la primera descarga de datos es necesaria una conexión a internet. Intente nuevamente una vez que su dispositivo tenga conexión.',
              TYPE_ICON.ERROR
            );
          } else {
            setSchoolarships(files);
          }
        })
        .catch((e) =>
          showToast(
            'Error',
            'No se ha podido leer la información de becas',
            TYPE_ICON.ERROR
          )
        )
        .finally(() => {
          dispatch(hideLoader());
        });
    }
  }, [institution, dispatch, user.email, user.keyDecrypt, isOnline]);

  return schoolarships;
};

export default useSchoolarShips;

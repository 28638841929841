import React from 'react';
import defaultImgTask from '../assets/imgs/tasks.svg';
import '../styles/components/SelectedTask.scss';

export default function SelectedTask({ task }) {
  const {
    notDescripcion: description,
    archivoImagen: image,
    notTitulo: title,
    fecha: date,
    notHRef: url,
  } = task;
  return (
    <div className="selected-task">
      <div className="selected-task-container mb-md-3 w-md-75 mx-auto">
        <div className="selected-task-icon">
          <img src={image ?? defaultImgTask} alt={title} />
        </div>
        <div className="main-task-content ms-2">
          <span className="selected-task-title font-bold d-block">{title}</span>
          <small className="badge bg-orange mb-3">{date}</small>
          <div className="main-task-text text-primary f-12px ">
            {description}
          </div>
          {url && (
            <a className="text-orange mt-3 d-inline-block f-12px" href={url}>
              Recursos adicionales
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

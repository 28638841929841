import Config from '../config/general-config';

const getToken = () => {
  return localStorage.getItem(Config.LOCALSTORAGE_KEYS.JWT);
};
const setToken = (token) => {
  localStorage.setItem(Config.LOCALSTORAGE_KEYS.JWT, token);
};
const deleteToken = (token) => {
  localStorage.removeItem(Config.LOCALSTORAGE_KEYS.JWT);
};

const getActiveUser = () =>
  localStorage.getItem(Config.LOCALSTORAGE_KEYS.ACTIVE_USER);

const setActiveUser = (id) =>
  localStorage.setItem(Config.LOCALSTORAGE_KEYS.ACTIVE_USER, id);

const clearActiveUser = () =>
  localStorage.removeItem(Config.LOCALSTORAGE_KEYS.ACTIVE_USER);

const getShoppingCar = () => {
  if (getActiveUser())
    return JSON.parse(localStorage.getItem(`car:${getActiveUser()}`));
  return [];
};

const setShoppingCar = (products) => {
  localStorage.setItem(`car:${getActiveUser()}`, JSON.stringify(products));
};

const cleanShoppingCart = () => {
  localStorage.removeItem(`car:${getActiveUser()}`);
};

export {
  getToken,
  setToken,
  deleteToken,
  getShoppingCar,
  setShoppingCar,
  getActiveUser,
  setActiveUser,
  clearActiveUser,
  cleanShoppingCart,
};

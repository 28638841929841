import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import TitleModule from '../core/TitleModule';
import './../../styles/components/EducationalPath.scss';
import FormativeFields from '../FormativeFields';
import useEducationalPath from './../../hooks/useEducationalPath';
import StatusGradeEducationalPath from '../StatusGradeEducationalPath';
import Config from '../../config/general-config';
import NoContent from '../generic/NoContent';
import useEvaluations from '../../hooks/useEvaluations';

export default function EducationalPath() {
  const path = useEducationalPath([]);
  const [activeGrade, setActiveGrade] = useState(null);
  const [activeEvaluationTime, setActiveEvaluationTime] = useState(null);
  const [gradeSchoolar, setGradeSchoolar] = useState('');
  const [mappedPath, setMappedPath] = useState([]);
  const [level, setLevel] = useState(1);
  const evaluations = useEvaluations(
    activeEvaluationTime?.idPeriodo,
    activeGrade
  );

  useEffect(() => {
    if (path.length === 0) {
      return;
    }

    let _mappedPath = [];
    const _level = path[0].idNivel;
    setLevel(_level);
    const loopLimit = Config.GRADES_BY_LEVEL[_level];
    for (let i = 1; i <= loopLimit; i++) {
      _mappedPath.push(path.find((item) => item.idGrado === i.toString()));
    }
    setMappedPath(_mappedPath);
    const _gradeSchoolar = path.find(
      (item, index) => item.nombreNivel
    ).nombreNivel;
    setGradeSchoolar(_gradeSchoolar);
    const activeGrade = path.find((item) => item.estatusAlumnoGrupo);
    setActiveGrade(activeGrade);
    setActiveEvaluationTime(activeGrade.periodos[0]);
  }, [path, level]);

  return (
    <React.Fragment>
      <TitleModule title="Trayectoria Educativa" />
      <Container className="">
        <Row>
          <Col xs={12}>
            <div className="font-bold text-orange">{gradeSchoolar}</div>
          </Col>
        </Row>
        <Row className="mt-4 mb-2">
          <Col className="">
            <div className="verdana-font description-path">
              A continuación se presenta un resumen de la información del alumno
              en relación a su trayectoria educativa, permitiendo identificar
              aquellos grados que han sido cursados y concluidos, el grado en el
              que se encuentra actualmente inscrito, así como los grados que
              tiene por concluir en el nivel educativo actual.
            </div>
          </Col>
        </Row>
        <NoContent
          thereAreContent={path.length > 0}
          title="No se cuenta con información de este alumno"
          icon="far fa-user"
        >
          <Row>
            <Col xs={12}>
              <div className="alert alert-info">
                <div className="text-primary">
                  <span className="font-bold">Tiene beca: </span>
                  <span>{activeGrade?.tieneBeca ? 'SÍ' : 'NO'}</span>
                </div>
                {activeGrade?.tieneBeca && (
                  <div className="text-primary">
                    <span className="font-bold">Beca: </span>
                    <span>
                      {activeGrade?.becaAlumno?.nombreTipoBeca}&nbsp;
                      <span>({activeGrade?.becaAlumno?.porcentaje}%)</span>
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="text-center mb-3">
            <Col xs={12} md={{ span: 6, offset: 3 }}>
              <Row>
                <Col xs={12} className="mb-3">
                  <span className="text-uppercase text-primary monserrat-bold-font">
                    {activeGrade?.nombreCicloEscolar}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="text-center my-4 status-grades">
            {mappedPath.map((item, index) => (
              <StatusGradeEducationalPath
                key={item?.key || index}
                item={item}
                index={index}
                activeGrade={activeGrade}
                handleClick={setActiveGrade}
                level={level}
              ></StatusGradeEducationalPath>
            ))}
          </Row>
          <Row className="my-4">
            <Col xs={12} className="mb-4">
              <span className="font-bold text-primary monserrat-bold-font text-uppercase">
                Periodos de evaluación
              </span>
            </Col>
            <Col className="d-flex justify-content-around" xs={12}>
              {activeGrade?.periodos?.map((periodo, index) => (
                <div
                  className={`periodo text-center `}
                  key={periodo.idPeriodo}
                  onClick={() => setActiveEvaluationTime(periodo)}
                >
                  <div
                    className={`evaluation-time ${
                      activeEvaluationTime.idPeriodo === periodo.idPeriodo
                        ? 'active'
                        : ''
                    }`}
                  >
                    {index + 1}
                  </div>
                </div>
              ))}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormativeFields fields={evaluations} level={level} />
            </Col>
          </Row>
        </NoContent>
      </Container>
    </React.Fragment>
  );
}

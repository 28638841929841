import React, { useEffect, useState } from 'react';
import '../../styles/components/RequestProcedure.scss';
import TitleModule from './../core/TitleModule';
import OnlineContent from './../core/OnlineContent';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import ProceduresService from '../../services/procedures.service';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../../stores/slices/loaderSlice';
import { showToast, TYPE_ICON } from '../../helpers/sweetAlert.helper';
import ProcedureDocuments from '../ProcedureDocuments';
import TracingProcess from '../TracingProcess';
import Config from '../../config/general-config';
import ResultProcedure from '../ResultProcedure';

export default function RequestProcedure({ editMode = false }) {
  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  const [procedureActive, setProcedureactive] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const student = useSelector((state) => state.student);
  const [dateRequest, setDateRequest] = useState(new Date());

  useEffect(() => {
    const fetchContent = async () => {
      const errorMessage = editMode
        ? 'No se han podido recuperar los detalles de la solicitud'
        : 'No se ha podido recuperar los requisitos del trámite';
      try {
        dispatch(showLoader());
        let response = false;
        if (editMode) {
          response = await ProceduresService.detailProcedure(id, user.idPadre);
        } else {
          response = await ProceduresService.getProcedureRequestDetail(id);
        }
        if (!response) {
          throw new Error(errorMessage);
        }
        response.lstDocumentosSolicitados =
          typeof response.lstDocumentosSolicitados === 'string'
            ? []
            : response.lstDocumentosSolicitados;
        setDetail(response);
        setDateRequest(response?.fechaSolicitud ?? new Date());
      } catch (e) {
        showToast('Error', errorMessage, TYPE_ICON.ERROR);
      } finally {
        dispatch(hideLoader());
      }
    };
    fetchContent();
  }, [id, dispatch, editMode, user.idPadre]);

  useEffect(() => {
    if (!detail) {
      return;
    }
    const _procedureActive = !(
      detail.idEstatusSolicitud === Config.PROCEDURES_STATUS.CANCELED ||
      detail.idEstatusSolicitud ===
        Config.PROCEDURES_STATUS.CANCELED_BY_MANAGEMENT ||
      detail.idEstatusSolicitud === Config.PROCEDURES_STATUS.FINISHED ||
      detail.idEstatusSolicitud === Config.PROCEDURES_STATUS.REQUESTED
    );
    setProcedureactive(_procedureActive);
  }, [detail]);

  const handleClick = async () => {
    try {
      dispatch(showLoader());
      const idRequest = await ProceduresService.createProcedure(
        user.idPadre,
        id,
        student.id
      );
      if (!idRequest) {
        throw new Error('No se ha podido crear la solicitud');
      }
      navigate(`/solicitar-tramite/${idRequest}`);
    } catch (e) {
      const message = editMode
        ? 'Ha ocurrido un error al actualizar los datos de la solicitud'
        : 'Ha ocurrido un error al crear la solicitud';
      showToast('Error', message, TYPE_ICON.ERROR);
    } finally {
      dispatch(hideLoader());
    }
  };

  const onUploadFile = () => {
    setDetail({ ...detail });
  };

  return (
    detail && (
      <div className="request-procedure">
        <TitleModule title="Solicitud de Trámite" />
        <OnlineContent>
          <Container>
            <Row>
              <Col xs={12}>
                <h5>
                  <span>{detail.servicio}</span>
                  <span className="badge bg-primary ms-3">
                    {detail.estatusSolicitud}
                  </span>
                </h5>
                <h6 className="text-orange font-bold monserrat-font">
                  {detail.nombreAlumno}
                </h6>
              </Col>
            </Row>
            {editMode && (
              <Row className="mt-3">
                <Col xs={12}>
                  {detail?.lstDocumentosFinales && (
                    <React.Fragment>
                      <hr></hr>
                      <ResultProcedure
                        documents={detail?.lstDocumentosFinales}
                      ></ResultProcedure>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            )}
            {editMode && (
              <Row>
                <Col xs={12}>
                  <TracingProcess
                    idRequest={id}
                    dateRequest={dateRequest}
                    status={detail.idEstatusSolicitud}
                  ></TracingProcess>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                {detail?.lstDocumentosSolicitados && (
                  <ProcedureDocuments
                    idRequest={id}
                    documents={detail.lstDocumentosSolicitados}
                    onUploadFile={onUploadFile}
                    allowUpload={editMode && procedureActive}
                  ></ProcedureDocuments>
                )}
              </Col>
            </Row>
            {!editMode && (
              <Row>
                <Col xs={12} className="text-center my-5">
                  <button
                    type="button"
                    className="btn bg-orange text-white font-bold"
                    onClick={handleClick}
                  >
                    Iniciar Trámite
                  </button>
                </Col>
              </Row>
            )}
          </Container>
        </OnlineContent>
      </div>
    )
  );
}

import React from 'react';
import Config from '../config/general-config';
import { ReactComponent as IconNotice } from '../assets/imgs/notification_icon.svg';
import { ReactComponent as IconRemainder } from '../assets/imgs/bell.svg';

export default function SelectedNotice({ notification }) {
  const {
    tipoNotificacion: type,
    notTitulo: title,
    notDescripcion: description,
    notHRef: url,
    fecha: date,
  } = notification;
  return (
    <div className="main-notice">
      <div className="main-notice-container mb-3 w-md-75 mx-auto">
        <div
          className={`main-notice-icon ${
            type === Config.TYPE_NOTIFICATION.REMAINDER ? 'remainder' : ''
          } ${type === Config.TYPE_NOTIFICATION.NOTICE ? 'notice' : ''}`}
        >
          {type === Config.TYPE_NOTIFICATION.REMAINDER && <IconRemainder />}
          {type === Config.TYPE_NOTIFICATION.NOTICE && <IconNotice />}
        </div>
        <div className="main-notice-text text-primary f-12px font-bold">
          <span className="d-block">{title}</span>
          <small className="badge bg-info mb-3">{date}</small>
          <span className="d-block">{description}</span>
          {url && (
            <a className="text-orange mt-3 d-inline-block" href={url}>
              Recursos adicionales
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

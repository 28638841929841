import baseService from './base.service';
import Config from './../config/general-config';

const recaptchaValidateService = {
  validateToken: async (token) => {
    const response = await baseService.postRequest(
      Config.ENDPOINTS.RECAPTCHA_VALIDATE,
      null,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          keyGoogle: Config.captcha_secret,
          token,
        },
      }
    );
    return response;
  },
};

export default recaptchaValidateService;

import React from 'react';
import './../styles/components/SchoolarshipsTypes.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getDateWithFormat } from './../utils/dates.util';

export default function SchoolarshipsTypes({
  showModal,
  onClose,
  types,
  startProcess = false,
}) {
  const student = useSelector((state) => state.student);
  return (
    <div className="schoolarships-types">
      <Modal size={'lg'} show={showModal} onHide={onClose} centered>
        <Modal.Header closeButton className="bg-orange text-white">
          <Modal.Title>
            <span>Tipos de becas</span>
            <small className="f-14px font-bold verdana-font d-block mt-3">
              {student.name}
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {types &&
            types.map((type) => (
              <div className="schoolarship-type" key={type.idTipoBeca}>
                <div className="schoolarship-type-title">{type.tipoBeca}</div>
                <div className="schoolarship-type-description">
                  {type.descripcion}
                </div>
                <div className="schoolarship-type-options text-end">
                  {startProcess && type.estatus && !type.becaSolicitada && (
                    <Link
                      to={`/solicitar-beca/${type.idTipoBeca}`}
                      className="bg-primary text-white font-bold start-process-schoolarship"
                    >
                      Iniciar trámite
                      <i className="fa-solid fa-arrow-right ms-2"></i>
                    </Link>
                  )}
                  {startProcess && type.becaSolicitada && (
                    <small className="text-primary font-italic">
                      Beca solicitada el{' '}
                      {getDateWithFormat(
                        'DD [de] MMMM [de] YYYY hh:mm a',
                        type.fechaSolicitud
                      )}
                    </small>
                  )}
                </div>
              </div>
            ))}
          {(!types || types.length === 0) && (
            <div className="schoolarship-type">
              <p>No existe información para mostrar</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="bg-primary text-white" onClick={onClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

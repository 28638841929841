import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../components/pages/Login';
import PrivateRouterOutlet from './../components/core/PrivateRouterOutlet';

export default function RouterApp() {
  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/*" element={<PrivateRouterOutlet />}></Route>
    </Routes>
  );
}

import React from 'react';
import './../styles/components/TaskItem.scss';
import seeIcon from './../assets/imgs/eye.svg';
import magnifierIcon from './../assets/imgs/magnifier.svg';
// import requestIcon from './../assets/imgs/magnifier.svg';
// import uploadIcon from './../assets/imgs/upload.svg';
import defaultTaskIcon from '../assets/imgs/tasks.svg';

export default function TaskItem({ task, activeTask, active }) {
  const {
    archivoImagen: image,
    fecha: date,
    notDescripcion: description,
    notTitulo: title,
    read,
  } = task;
  return (
    <div
      className={`task-item ${active ? 'active' : ''}`}
      onClick={() => activeTask(task)}
    >
      <div className="task-item-container cursor-pointer">
        <div className="task-image-container ga_image">
          <img
            src={image ?? defaultTaskIcon}
            alt="Imagen de apoyo"
            className="task-image"
          />
        </div>
        <div className="task-date-container ga_date">
          <div className="task-date monserrat-bold-font text-primary">
            {date}
          </div>
        </div>
        <div className="task-author-container ga_author">
          <div className="task-author verdana-font text-uppercase font-bold">
            {title}
          </div>
        </div>
        <div className="task-description-container ga_description">
          <div className="task-description verdana-font text-primary">
            {description}
          </div>
        </div>
        <div className="task-options-container ga_options">
          <div className="task-options">
            <div className="task-option">
              <img src={read ? seeIcon : magnifierIcon} alt="Ver" />
            </div>
            {/* TODO: Not implemented in this stage */}
            {/* <div className="task-option">
              <img src={requestIcon} alt="Consultar" />
            </div> */}
            {/* <div className="task-option">
              <img src={uploadIcon} alt="Subir" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

import User from './../models/user.model';

class UserController {
  #model;

  constructor(email, secretKey) {
    this.#model = new User(email, secretKey);
  }

  addUser(user) {
    return this.#model.addUser(user);
  }

  readUser() {
    return this.#model.readUser();
  }

  readStudents() {
    return this.#model.readStudents();
  }
}

export default UserController;

import React, { useState, useEffect } from 'react';
import '../../styles/components/FileViewer.scss';
import { downloadFile, getExtentionFromMimeType } from './../../utils/utils';
import PDFViewer from './PDFViewer';
import useWindowDimensions from './../../hooks/useWindowDimensions';
import { showToast, TYPE_ICON } from '../../helpers/sweetAlert.helper';

export default function FileViewer({ file }) {
  const [previewFile, setPreviewFile] = useState(null);
  const [isImage, setIsImage] = useState(false);
  const [isPDF, setIsPDF] = useState(false);
  const { width, height } = useWindowDimensions();

  const styles = {
    embed_h: {
      maxWidth: '95%',
      height: 'auto',
    },
    embed_w: {
      maxHeight: '95vh',
      width: 'auto',
    },
  };

  useEffect(() => {
    try {
      if (!file) {
        return;
      }
      const ext = getExtentionFromMimeType(file.mimeType);
      const _isPDF = ext === '.pdf';
      setIsPDF(_isPDF);
      const _isImage = ['.png', '.gif', '.jpg', '.jpeg'].includes(ext);
      setIsImage(_isImage);

      if (_isImage || _isPDF) {
        setPreviewFile(file);
      } else {
        downloadFile(file);
      }
    } catch (e) {
      showToast(
        'Error',
        'No se ha podido cargar el archivo para su previsualización',
        TYPE_ICON.ERROR
      );
    }
  }, [file]);

  return (
    previewFile && (
      <div className="file-viewer">
        <div className="file-viewer-container">
          <div
            className="go-back p-2"
            onClick={() => {
              setPreviewFile(null);
            }}
          >
            <i className="fa-solid fa-times text-white fa-2x cursor-pointer"></i>
          </div>
          <div
            className="btn-download p-2"
            onClick={() => {
              downloadFile(file);
            }}
          >
            <i className="fas fa-cloud-download-alt text-white fa-2x cursor-pointer"></i>
          </div>
          {isImage && (
            <embed
              style={width > height ? styles.embed_w : styles.embed_h}
              src={`data:${previewFile.mimeType};base64,${previewFile.fileData}`}
              type={previewFile.mimeType}
            ></embed>
          )}
          {isPDF && <PDFViewer file={previewFile} />}
        </div>
      </div>
    )
  );
}

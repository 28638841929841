import React from 'react';
import { ReactComponent as RemainderIcon } from './../assets/imgs/bell.svg';
import { ReactComponent as NoticeIcon } from './../assets/imgs/notification_icon.svg';
import { ReactComponent as EyeIcon } from './../assets/imgs/eye.svg';
import { ReactComponent as MagnifierIcon } from './../assets/imgs/magnifier.svg';
import './../styles/components/NoticeRemainderItem.scss';
import Config from './../config/general-config';
import { customFormatDate } from '../utils/dates.util';

export default function NoticeRemainderItem({
  active = false,
  notification,
  activateNotice,
}) {
  const {
    tipoNotificacion: type,
    notDescripcion: description,
    notTitulo: title,
    fechaHora: date,
    read,
  } = notification;
  return (
    <div
      className="notice-remainder f-12px"
      onClick={() => activateNotice(notification)}
    >
      <div
        className={`notice-remainder-container cursor-pointer d-flex ${
          active ? 'active' : ''
        }`}
      >
        <div
          className={
            `text-white notice-remainder-icon d-flex align-items-center ` +
            (type === Config.TYPE_NOTIFICATION.NOTICE ? 'bg-orange' : 'bg-teal')
          }
        >
          {type === Config.TYPE_NOTIFICATION.NOTICE ? (
            <NoticeIcon />
          ) : (
            <RemainderIcon />
          )}
        </div>
        <div className="notice-remainder-text">
          <span className="font-bold d-block">{title}</span>
          <span className="badge bg-info mb-3">
            {customFormatDate(date, 'MM/DD/YYYY HH:mm A', 'DD/MM/YYYY HH:mm a')}
          </span>
          <span className="notice-remainder-description">{description}</span>
        </div>
        <div className="notice-remainder-option d-flex align-items-center">
          {read ? <EyeIcon /> : <MagnifierIcon />}
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SoftwareLink from './SoftwareLink';

export default function NormatecaSoftwareList({ links }) {
  return (
    links && (
      <div className="normateca-software pb-3">
        <div className="text-white text-uppercase font-bold monserrat-font f-22px text-center mt-3 mb-4">
          Software Educativo y Ligas de Interés
        </div>
        <Row>
          {links.map((link) => (
            <Col xs={6} lg={3} key={link.idDocumento}>
              <SoftwareLink link={link}></SoftwareLink>
            </Col>
          ))}
        </Row>
      </div>
    )
  );
}

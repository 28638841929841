import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as UserDefaultAvatar } from './../assets/imgs/user_default.svg';
import { useSelector } from 'react-redux';
import '../styles/components/StudentMenu.scss';
import StudentItem from './StudentItem';
import useClickOutsideComponent from '../hooks/useClickOutsideComponent';
import CustomTooltip from './generic/CustomTooltip';
import { useLiveQuery } from 'dexie-react-hooks';
import StudentController from './../controllers/student.controller';

export default function StudentMenu() {
  const [showMenu, setShowMenu] = useState(false);
  const { ref, isClickOutside } = useClickOutsideComponent();
  const activeStudent = useSelector((state) => state.student);
  const user = useSelector((state) => state.user);
  const studentController = useRef(
    new StudentController(user.email, user.keyDecrypt)
  );
  const students = useLiveQuery(
    () => studentController.current.readStudents(user.id),
    [studentController, user]
  );

  useEffect(() => {
    if (isClickOutside) {
      setShowMenu(false);
    }
  }, [isClickOutside]);

  return (
    <React.Fragment>
      <div className="user-menu" ref={ref}>
        <figure
          className="user-img-container header-icon-container cursor-pointer"
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <CustomTooltip text={activeStudent.name} placement="bottom">
            {activeStudent.avatar ? (
              <img
                className="user-img"
                src={activeStudent.avatar}
                alt={activeStudent.name}
              />
            ) : (
              <UserDefaultAvatar />
            )}
          </CustomTooltip>
        </figure>
        {showMenu && (
          <div className="users-list-container">
            {students.map((student) => (
              <StudentItem
                student={student}
                key={student.curp}
                active={activeStudent.id === student.idAlumno}
                _click={() => setShowMenu(false)}
              />
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

import React from 'react';
import calendarIcon from '../assets/imgs/calendar.svg';
import './../styles/components/Calendar.scss';
import { Link } from 'react-router-dom';

export default function Calendar() {
  return (
    <div className="calendar">
      <Link
        className="calendar-icon-container header-icon-container cursor-pointer"
        to="/"
      >
        <img
          src={calendarIcon}
          alt="Calendario"
          className="calendar-icon header-icon"
        />
      </Link>
    </div>
  );
}

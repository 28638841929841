import React, { useState } from 'react';
import '../../styles/components/Normateca.scss';
import { Container, Row, Col } from 'react-bootstrap';
import TitleModule from './../core/TitleModule';
import NormatecaDocumentsList from './../NormatecaDocumentsList';
import NormatecaSoftwareList from './../NormatecaSoftwareList';
import useNormateca from '../../hooks/useNormateca';
import FileViewer from './../generic/FileViewer';
import {
  showToast,
  TYPE_ICON,
  DURATION_TOAST,
} from '../../helpers/sweetAlert.helper';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../../stores/slices/loaderSlice';
import InstitutionController from './../../controllers/institution.controller';
import fileService from '../../services/file.service';

export default function Normateca() {
  const { files, interes: links } = useNormateca([]);
  const [previewFile, setPreviewFile] = useState(null);
  const dispatch = useDispatch();
  const { isOnline } = useSelector((state) => state.internet);
  const user = useSelector((state) => state.user);
  const institution = useSelector((state) => state.institution);

  const handleClickFile = async (fileData) => {
    try {
      dispatch(showLoader());
      const fileController = new InstitutionController(
        user.email,
        user.keyDecrypt
      );
      let _file = null;
      if (isOnline) {
        const response = await fileService.getDocument(fileData.idArchivo);
        if (!response) {
          showToast(
            'Documento no encontrado',
            'No se ha encontrado el documento en el servidor o no ha sido cargado',
            TYPE_ICON.WARNING
          );
          return;
        }
        _file = {
          name: fileData.documento,
          fileData: response.archivoBytes,
          mimeType: response.tipoArchivo,
        };
        await fileController.updateNormatecaFile({
          idInstitucion: institution.id,
          idDocumento: fileData.idDocumento,
          file: _file,
        });
      } else {
        _file = await fileController.readNormatecaFile(
          institution.id,
          fileData.idDocumento
        );
        if (!_file) {
          showToast(
            'Documento no almacenado localmente',
            'No se cuenta con una copia local del archivo para visualizarse sin conexión. Las copias locales se almacenan cuando el archivo se visualiza al menos una vez con conexión a internet activa',
            TYPE_ICON.INFO,
            DURATION_TOAST.INFINITE
          );
          return;
        }
      }
      if (_file) {
        setPreviewFile(_file);
      }
    } catch (e) {
      console.log(e);
      showToast(
        'Error',
        'No se ha podido previsualizar el archivo. Intente nuevamente por favor, si el problema persiste, contacte a la institución',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <React.Fragment>
      <FileViewer file={previewFile} />
      <div className="normateca">
        <TitleModule title="Normateca"></TitleModule>
        <Container className="normateca-container">
          <Row>
            <Col xs={12}>
              <div className="module-description verdana-font my-3">
                En esta sección de Normateca se dispone una{' '}
                <span className="font-bold">consulta de información</span>{' '}
                general:{' '}
                <span className="font-bold">documentos normativos</span>,
                archivos sobre{' '}
                <span className="font-bold">
                  convivencia escolar, ligas de interés y recursos educativos
                </span>
                , puestos a disposición por el plantel hacia la Comunidad
                Educativa.
              </div>
            </Col>
          </Row>
          <Row className="normateca-documents-list">
            <NormatecaDocumentsList
              files={files}
              handleClickFile={handleClickFile}
            ></NormatecaDocumentsList>
          </Row>
        </Container>
        {isOnline && (
          <Container fluid className="software-container mt-5">
            <Row>
              <Col xs={12}>
                <Container>
                  <Row>
                    <Col xs={12} className="py-3 px-0">
                      <NormatecaSoftwareList
                        links={links}
                      ></NormatecaSoftwareList>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
}

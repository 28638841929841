import React, { useRef, useState } from 'react';
import '../styles/components/ProcedureDocument.scss';
import CustomTooltip from './generic/CustomTooltip';
import eyeIcon from '../assets/imgs/eye.svg';
import cloudIcon from '../assets/imgs/upload.svg';
import Config from '../config/general-config';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import Validators from '../validators/validators';

const MAX_FILE_SIZE = 1;

export default function SchoolarshipDocument({
  document,
  allowUpload,
  handlePreview,
  handlePreviewFromServer,
  upload,
}) {
  const inputRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const uploadFile = async (event) => {
    const docFile = inputRef.current.files[0];
    if (!docFile) {
      return;
    }
    const { isValid, detail } = Validators.FileSizeAndType(
      docFile,
      MAX_FILE_SIZE,
      Config.validDocumentsExtentions
    );
    if (!isValid) {
      showToast('Tipo de archivo inválido', detail, TYPE_ICON);
      return;
    }
    setLoaded(true);
    upload({ id: document.idDocumento, file: docFile });
  };

  return (
    <div className="procedure-document">
      <div className="procedure-document-container">
        <div className="procedure-document-actions">
          <div className="procedure-document-icons">
            <CustomTooltip
              text={
                loaded
                  ? 'Documento en espera de ser cargado'
                  : 'Documento no seleccionado'
              }
            >
              <span className="file-status">
                {loaded ? (
                  <i className="fas fa-clock text-orange fa-2x"></i>
                ) : (
                  <i className="far fa-times-circle fa-2x"></i>
                )}
              </span>
            </CustomTooltip>
            {document.idArchivoFormato && (
              <CustomTooltip text="Descargar formato">
                <span
                  className="text-primary cursor-pointer"
                  onClick={() =>
                    handlePreviewFromServer(document.idArchivoFormato)
                  }
                >
                  <i className="fas fa-file-download fa-2x"></i>
                </span>
              </CustomTooltip>
            )}
            {loaded && (
              <CustomTooltip text="Previsualizar archivo seleccionado">
                <div
                  className="procedure-document-preview cursor-pointer"
                  onClick={() => handlePreview(inputRef.current.files[0])}
                >
                  <img src={eyeIcon} alt="Previsualizar" />
                </div>
              </CustomTooltip>
            )}
            {allowUpload && (
              <CustomTooltip text="Seleccionar archivo para carga">
                <div className="custom-file-input-container cursor-pointer">
                  <img
                    src={cloudIcon}
                    alt="Seleccionar Archivo"
                    onClick={handleUpload}
                  />
                  <input
                    type="file"
                    name=""
                    id=""
                    hidden
                    ref={inputRef}
                    accept={Config.validDocumentsExtentions}
                    onChange={uploadFile}
                  />
                </div>
              </CustomTooltip>
            )}
          </div>
          <div className="procedure-document-name-description">
            <label className="file-name d-block monserrat-font text-primary">
              {document.nombreDocumento}
            </label>
            <small className="f-10px text-gray-6">
              {document.indicaciones}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

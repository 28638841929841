import { rootStore } from '../stores/rootStore';
import { logout } from '../stores/slices/userSlice';
import { showMessage, TYPE_ICON } from '../helpers/sweetAlert.helper';

export const AxiosErrorHandler = async (error) => {
  console.log(error);
  if (error?.response?.status === 401) {
    await showMessage(
      'Sesión finalizada',
      'Su sesión ha finalizado, debe ingresar nuevamente para continuar',
      TYPE_ICON.INFO
    );
    rootStore.dispatch(logout());
    return {
      estatus: false,
      mensaje:
        'Su sesión ha finalizado, debe ingresar nuevamente para continuar',
      datos: null,
    };
  }
  if (error?.request) {
    return {
      estatus: false,
      mensaje:
        'No se ha podido comunicar con el servidor. Revise su conexión de red. Si el problema persiste, contacte al administrador del sistema',
      datos: null,
    };
  }
  return {
    estatus: false,
    mensaje:
      'Ha ocurrido un error desconocido. Contacte al administrador del sistema',
    datos: null,
  };
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  newVersion: false,
};

export const versionAppSlice = createSlice({
  name: 'versionApp',
  initialState,
  reducers: {
    newVersion: (state) => {
      state.newVersion = true;
    },
    versionUpdated: (state) => {
      state.newVersion = false;
    },
  },
});

export const { newVersion, versionUpdated } = versionAppSlice.actions;
export default versionAppSlice.reducer;

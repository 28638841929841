import React, { useEffect, useState } from 'react';
import '../../styles/components/RequestProcedure.scss';
import TitleModule from './../core/TitleModule';
import OnlineContent from './../core/OnlineContent';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../../stores/slices/loaderSlice';
import { showToast, TYPE_ICON } from '../../helpers/sweetAlert.helper';
import SchoolarshipDocuments from '../SchoolarshipDocuments';
import schoolarshipsService from '../../services/schoolarships.service';
import { getDateWithFormat } from './../../utils/dates.util';

export default function RequestSchoolarship() {
  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const student = useSelector((state) => state.student);
  const [loadedDocuments, setLoadedDocuments] = useState({});

  useEffect(() => {
    const fetchContent = async () => {
      try {
        dispatch(showLoader());
        let response = false;
        response = await schoolarshipsService.getRequierements(id, student.id);
        if (!response) {
          throw new Error(
            'No se ha podido recuperar los requisitos de la beca'
          );
        }
        response.documentosSolicitados =
          typeof response.documentosSolicitados === 'string'
            ? []
            : response.documentosSolicitados;
        setDetail(response);
      } catch (e) {
        showToast(
          'Error',
          'No se ha podido recuperar los requisitos de la beca',
          TYPE_ICON.ERROR
        );
      } finally {
        dispatch(hideLoader());
      }
    };
    fetchContent();
  }, [id, dispatch, user.idPadre, student.id]);

  const handleClick = async () => {
    try {
      if (
        Object.keys(loadedDocuments).length !==
        detail.documentosSolicitados.length
      ) {
        showToast(
          'Documentos faltantes',
          'Es necesario cargar todos los documentos solicitados',
          TYPE_ICON.WARNING
        );
        return;
      }
      dispatch(showLoader());
      const idRequest = await schoolarshipsService.requestSchoolarship(
        user.idPadre,
        id,
        student.id
      );
      if (!idRequest) {
        throw new Error('No se ha podido crear la solicitud');
      }
      let uploadedDocuments = [];
      if (detail.documentosSolicitados.length > 0) {
        uploadedDocuments = Object.values(loadedDocuments).map((document) => {
          const formData = new FormData();
          formData.append('doc', document.file);
          return schoolarshipsService.uploadDocument(
            idRequest,
            document.id,
            formData
          );
        });
      }
      await Promise.allSettled(uploadedDocuments);
      showToast(
        'Éxito',
        `La beca ha sido solicitada para el alumno ${student.name} exitósamente`,
        TYPE_ICON.SUCCESS
      );
      navigate('/becas');
    } catch (e) {
      showToast(
        'Error',
        'Ha ocurrido un error al crear la solicitud',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const onUploadFile = (fileData) => {
    setDetail({ ...detail });
    const { id } = fileData;
    loadedDocuments[id] = fileData;
    setLoadedDocuments({ ...loadedDocuments });
  };

  return (
    detail && (
      <div className="request-procedure">
        <TitleModule title={`Solicitud de ${detail.tipoBeca}`} />
        <OnlineContent>
          <Container>
            <Row>
              <Col xs={12}>
                <h5>{detail.descripcion}</h5>
                <h6 className="text-orange font-bold monserrat-font">
                  {student.name}
                </h6>
              </Col>
            </Row>
            {!detail.estatus || detail.becaSolicitada ? (
              <React.Fragment>
                <Row>
                  <Col
                    xs={12}
                    className="h-100 d-flex justify-content-center align-items-center flex-column mt-5 text-gray-3"
                  >
                    <i
                      className={`fas f-100px m-2 ${
                        detail.becaSolicitada
                          ? 'fa-file-signature'
                          : 'fa-calendar-times'
                      }`}
                    ></i>
                    <h5 className="">
                      {detail.becaSolicitada
                        ? `Esta beca ya se ha solicitado para ${
                            student.name
                          } el ${getDateWithFormat(
                            'DD [de] MMMM [de] YYYY hh:mm a',
                            detail.fechaSolicitud
                          )}`
                        : 'El periodo para solicitar este tipo de beca ha vencido'}
                    </h5>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {detail?.documentosSolicitados && (
                  <Row>
                    {detail.documentosSolicitados.length > 0 && (
                      <Col
                        xs={12}
                        className="d-flex justify-content-center align-items-center mt-4"
                      >
                        <label className="counter-loaded-documents-label">
                          Documentos cargados:{' '}
                        </label>
                        <div className="counter-loaded-documents">
                          <span className="loaded">
                            {Object.keys(loadedDocuments).length}
                          </span>
                          <span>&nbsp;/&nbsp;</span>
                          <span className="total">
                            {detail.documentosSolicitados.length}
                          </span>
                        </div>
                      </Col>
                    )}
                    <Col xs={12}>
                      <SchoolarshipDocuments
                        idRequest={id}
                        documents={detail.documentosSolicitados}
                        upload={onUploadFile}
                        allowUpload={true}
                      ></SchoolarshipDocuments>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12} className="text-center my-5">
                    <button
                      type="button"
                      className="btn bg-orange text-white font-bold"
                      onClick={handleClick}
                      disabled={
                        Object.keys(loadedDocuments).length !==
                        detail.documentosSolicitados.length
                      }
                    >
                      Solicitar beca
                    </button>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Container>
        </OnlineContent>
      </div>
    )
  );
}

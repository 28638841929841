import React from 'react';
import FormativeField from './FormativeField';
import Config from '../config/general-config';

export default function FormativeFields({ level, fields }) {
  return (
    <React.Fragment>
      <div className="mt-4 types-of-formative-fields">
        <div className="monserrat-bold-font text-primary text-uppercase">
          {level === Config.SCHOOLAR_LEVELS.PREESCOLAR
            ? 'Campo Formativo'
            : 'Materia'}
        </div>
        <div>
          <span className="formative-field-type formative-field-C">C</span>{' '}
          Curricular
        </div>
        <div>
          <span className="formative-field-type formative-field-EC">EC</span>{' '}
          Extra Curricular
        </div>
        <div className="monserrat-bold-font text-primary text-uppercase ms-auto">
          Evaluación
        </div>
      </div>
      <div className="formative-fields-container my-4">
        {fields.map((field) => (
          <FormativeField field={field} key={field.idMateria}></FormativeField>
        ))}
      </div>
    </React.Fragment>
  );
}

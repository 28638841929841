import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const TYPE_ICON = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  QUESTION: 'question',
};

const DURATION_TOAST = {
  SHORT: 2500,
  MEDIUM: 4000,
  LONG: 5000,
  INFINITE: 0,
};

async function confirmAction(title, message) {
  const mySwal = withReactContent(Swal);
  const configSwal = {
    title: title,
    html: message,
    icon: TYPE_ICON.QUESTION,
    confirmButtonText: '<span class="fw-bold">Confirmar</span>',
    confirmButtonColor: '#FF784E',
    showCancelButton: true,
    cancelButtonColor: '',
    cancelButtonText: '<span class="fw-bold">Cancelar</span>',
    backdrop: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
  };
  const { isConfirmed } = await mySwal.fire(configSwal);
  return isConfirmed;
}

async function showMessage(title, message, icon = TYPE_ICON.INFO) {
  const mySwal = withReactContent(Swal);
  const configSwal = {
    title: title,
    html: message,
    icon,
    confirmButtonText: '<span class="fw-bold">Confirmar</span>',
    confirmButtonColor: '#FF784E',
    showCancelButton: false,
    backdrop: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
  };
  const { isConfirmed } = await mySwal.fire(configSwal);
  return isConfirmed;
}

function showToast(
  title,
  html,
  icon = TYPE_ICON.INFO,
  duration = DURATION_TOAST.MEDIUM
) {
  const mySwal = withReactContent(Swal);
  const customToast = mySwal.mixin({
    toast: true,
    position: 'top-right',
    iconColor: 'white',
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton: false,
    timer: duration,
    timerProgressBar: true,
    showCloseButton: true,
  });
  customToast.fire({ title, icon, html });
}

async function inputData(text, validator, description = '') {
  const mySwal = withReactContent(Swal);
  const configSwal = {
    title: text,
    html: description,
    confirmButtonText:
      '<span class="font-bold">Aceptar <i class="bi bi-arrow-right"></i></span>',
    confirmButtonColor: '#ff784e',
    showCloseButton: true,
    backdrop: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
    input: 'text',
    inputPlaceholder: description,
    inputValidator: (value) => {
      const isValid = validator(value);
      if (!isValid) {
        return 'La información capturada no es válida';
      }
    },
  };
  const { value } = await mySwal.fire(configSwal);
  return value;
}

export {
  confirmAction,
  showToast,
  showMessage,
  inputData,
  TYPE_ICON,
  DURATION_TOAST,
};

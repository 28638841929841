import React, { useEffect, useState, useCallback, useRef } from 'react';
import './../styles/components/TracingProcess.scss';
import { useForm } from 'react-hook-form';
import { getDateWithFormat, getDateTime } from './../utils/dates.util';
import ProceduresService from '../services/procedures.service';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ErrorInputMessage from './generic/ErrorInputMessage';
import { showLoader, hideLoader } from './../stores/slices/loaderSlice';
import FileViewer from './generic/FileViewer';
import fileService from '../services/file.service';
import Config from '../config/general-config';
import { calculateSizeOfFile } from '../utils/utils';
import Validators from '../validators/validators';

const MAX_LENGTH_COMMENT = 250;
const MAX_SIZE_ATTACHMENTS = 1;

export default function TracingProcess({ idRequest, dateRequest, status }) {
  const [tracing, setTracing] = useState([]);
  const [attachedFile, setAttachedFile] = useState(null);
  const [disableTracing, setDisableTracing] = useState(true);
  const [attachedFileToNewMessage, setAttachedFileToNewMessage] =
    useState(null);
  const [hideTracing, setHideTracing] = useState(false);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);
  const bottomRefTracing = useRef(null);
  const fileAttachment = useRef(null);
  const dispatch = useDispatch();
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: getDateWithFormat('YYYY-MM-DD'),
      time: getDateWithFormat('HH:mm'),
      comments: '',
    },
  });
  const comments = watch('comments', '');
  const fetchTracing = useCallback(async () => {
    try {
      setLoading(true);
      let _tracing = await ProceduresService.tracing(idRequest);
      if (!_tracing) {
        throw new Error('No se ha podido obtener el seguimiento del trámite');
      }
      _tracing = typeof _tracing === 'string' ? [] : _tracing;
      setTracing(_tracing);
    } catch (e) {
      showToast(
        'Error',
        'No se ha podido obtener el seguimiento del trámite',
        TYPE_ICON.ERROR
      );
    } finally {
      setLoading(false);
    }
  }, [idRequest]);

  useEffect(() => {
    const setDateData = (date) => {
      const parsedDate = getDateTime(date);
      setValue('date', parsedDate.date);
      setValue('time', parsedDate.time);
    };
    setDateData(dateRequest);
  }, [dateRequest, setValue]);

  useEffect(() => {
    fetchTracing();
  }, [idRequest, fetchTracing]);

  useEffect(() => {
    if (!bottomRefTracing.current) return;
    bottomRefTracing.current.scrollTop = bottomRefTracing.current.scrollHeight;
  }, [tracing]);

  useEffect(() => {
    const disable =
      status === Config.PROCEDURES_STATUS.CANCELED ||
      status === Config.PROCEDURES_STATUS.CANCELED_BY_MANAGEMENT ||
      status === Config.PROCEDURES_STATUS.FINISHED;
    setDisableTracing(disable);
  }, [status]);

  const onSubmit = async (data) => {
    if (disableTracing) {
      showToast(
        'Acción no permitida',
        'Ya no es posible agregar un comentario de seguimiento a esta solicitud',
        TYPE_ICON.INFO
      );
      return;
    }
    const { comments } = data;
    try {
      dispatch(showLoader());
      let response = await ProceduresService.addTrack(
        idRequest,
        comments,
        user.id
      );
      const { estatus, mensaje, datos } = response;
      if (!estatus) {
        throw new Error(mensaje);
      }
      if (attachedFileToNewMessage) {
        const formData = new FormData();
        formData.append('doc', attachedFileToNewMessage);
        response = await ProceduresService.attachFileToMessage(datos, formData);
        if (!response) {
          throw new Error(
            'No se ha podido adjuntar el archivo al mensaje enviado'
          );
        }
      }
      fetchTracing();
    } catch (e) {
      showToast(
        'Error',
        e.message ||
          'No se han podido guardar sus comentarios. Intente nuevamente por favor o contacte a un administrador del sistema',
        TYPE_ICON.ERROR
      );
      dispatch(hideLoader());
    } finally {
      setValue('comments', '');
      resetFileInput();
      dispatch(hideLoader());
    }
  };

  const handleAttachmentClick = async (idAttachment) => {
    try {
      dispatch(showLoader());
      let _attachedFile = await fileService.getDocument(idAttachment);
      if (!_attachedFile) {
        throw new Error('No se ha podido obtener el archivo adjunto');
      }
      _attachedFile = {
        name: idAttachment,
        fileData: _attachedFile.archivoBytes,
        mimeType: _attachedFile.tipoArchivo,
      };
      setAttachedFile(_attachedFile);
    } catch (e) {
      showToast(
        'Error',
        'No se ha podido obtener el archivo adjunto',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const resetFileInput = () => {
    fileAttachment.current.value = null;
    setAttachedFileToNewMessage(null);
  };

  const handleFileChooserAttachToMessage = () => {
    fileAttachment.current.click();
  };

  const handleAttachFileToMessage = (e) => {
    const file = fileAttachment.current.files[0];
    const { isValid, detail } = Validators.FileSizeAndType(
      file,
      MAX_SIZE_ATTACHMENTS,
      Config.validDocumentsExtentions
    );

    if (!isValid) {
      showToast('Archivo inválido', detail, TYPE_ICON.WARNING);
      resetFileInput();
      return;
    }
    setAttachedFileToNewMessage(file);
  };

  return (
    <div className="tracing-process">
      <FileViewer file={attachedFile}></FileViewer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mt-4">
          <Col xs={12} md={4}>
            <div className="input-control">
              <label htmlFor="date">Fecha:</label>
              <input
                id="date"
                type="date"
                className={`d-block w-100 input-login ${
                  errors.date ? 'has-error' : ''
                }`}
                placeholder="Fecha"
                {...register('date', {})}
                disabled
              />
              <ErrorInputMessage
                show={errors.date}
                message="La fecha no tiene un formato válido"
                classes="text-orange"
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="input-control">
              <label htmlFor="time">Hora:</label>
              <input
                id="hora"
                type="time"
                className={`d-block w-100 input-login ${
                  errors.time ? 'has-error' : ''
                }`}
                placeholder="Hora"
                {...register('time', {})}
                disabled
              />
              <ErrorInputMessage
                show={errors.time}
                message="La hora no tiene un formato válido"
                classes="text-orange"
              />
            </div>
          </Col>
        </Row>
        <Row className="tracing-request-container">
          <Col xs={12} className="tracing-messages-title">
            <h5 className="text-primary">Seguimiento de trámite</h5>
            <div
              className={`toggle-tracing-messages ${hideTracing ? '' : 'open'}`}
              onClick={() => setHideTracing(!hideTracing)}
            ></div>
          </Col>
          <Col
            xs={12}
            className={`animated ${hideTracing ? 'd-none fadeOut' : 'fadeIn'}`}
          >
            {loading ? (
              <div className="loader-tracing p-3 text-center">
                <i className="fas fa-sync fa-spin fa-2x text-primary"></i>
              </div>
            ) : (
              <div
                className="tracing-messages-container"
                ref={bottomRefTracing}
              >
                {tracing.map((trace) => (
                  <div
                    className={`trace-message ${
                      trace.seguimientoPadre ? 'tutor-message' : ''
                    }`}
                    key={trace.idSeguimiento}
                  >
                    <div className="trace-message-autor">
                      {trace.nombreAutor}
                    </div>
                    <div className="trace-message-date">
                      {getDateWithFormat(
                        'DD [de] MMMM [de] YYYY hh:mm a',
                        trace.fechaHora
                      )}
                    </div>
                    <div className="trace-message-text my-2">
                      {trace.observaciones}
                    </div>
                    {trace.idArchivoAdjunto && (
                      <div
                        className="trace-message-attachment cursor-pointer bg-primary text-white"
                        onClick={() =>
                          handleAttachmentClick(trace.idArchivoAdjunto)
                        }
                      >
                        <i className="fas fa-paperclip">&nbsp;</i>Archivo
                        adjunto
                      </div>
                    )}
                  </div>
                ))}
                {(!tracing || tracing.length === 0) && (
                  <h5 className="text-gray-3 w-100 text-center">
                    Aún no existen datos de seguimiento
                  </h5>
                )}
                <div />
              </div>
            )}
          </Col>
          <Col
            xs={12}
            className={`animated ${hideTracing ? 'd-none fadeOut' : 'fadeIn'}`}
          >
            <textarea
              rows="3"
              col="30"
              className="w-100 p-2"
              placeholder="Comentarios"
              maxLength={MAX_LENGTH_COMMENT}
              {...register('comments', {
                maxLength: MAX_LENGTH_COMMENT,
                validate: (value) => Validators.TrimedString(value),
              })}
              disabled={disableTracing}
            ></textarea>
            <div className="text-end">
              <small className="text-primary text-bold">
                {`${comments.length}/${MAX_LENGTH_COMMENT}`}
              </small>
            </div>
            <ErrorInputMessage
              show={errors.comments}
              message={`Escriba un mensaje válido entre 1 y ${MAX_LENGTH_COMMENT} caracteres. No son válidos mensajes conformados por espacios únicamente`}
              classes="text-orange"
            />
          </Col>
          <Col
            xs={12}
            className={`animated ${hideTracing ? 'd-none fadeOut' : 'fadeIn'}`}
          >
            <div className="tracing-request-options d-flex justify-content-end mt-3">
              <input
                type="file"
                name=""
                id=""
                hidden
                ref={fileAttachment}
                accept={Config.validDocumentsExtentions}
                onChange={handleAttachFileToMessage}
              />
              {attachedFileToNewMessage && (
                <small className="font-bold text-primary align-self-center me-2">
                  {attachedFileToNewMessage.name}(
                  {calculateSizeOfFile(attachedFileToNewMessage.size)} MB)
                </small>
              )}
              <button
                type="button"
                className="btn bg-primary text-white me-2"
                onClick={handleFileChooserAttachToMessage}
                disabled={disableTracing || errors.comments}
              >
                <i className="fas fa-paperclip"></i>
              </button>
              <button
                type="submit"
                className="btn bg-orange text-white font-bold"
                disabled={disableTracing || errors.comments}
              >
                <i className="fas fa-comments">&nbsp;</i>Enviar
              </button>
            </div>
            <div className="text-end">
              <small className="text-gray-3">{`Peso máximo de los archivo adjuntos ${MAX_SIZE_ATTACHMENTS} Mb`}</small>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOnline: window.navigator.onLine,
};

export const internetSlice = createSlice({
  name: 'internet',
  initialState,
  reducers: {
    online: (state) => {
      state.isOnline = true;
    },
    offline: (state) => {
      state.isOnline = false;
    },
  },
});

export const { online, offline } = internetSlice.actions;
export default internetSlice.reducer;

import React from 'react';
import logoDefault from './../assets/imgs/institution_default.jpg';
import './../styles/components/LogoInstitution.scss';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function LogoInstitution() {
  const logo = useSelector((state) => state.institution.logo);
  return (
    <Link className="logo-institution" to="/">
      <figure className="logo-institution-container d-flex justify-content-center align-content-center no-focus">
        <img
          src={logo ? logo : logoDefault}
          alt="Institución"
          className="logo-institution-img"
        />
      </figure>
    </Link>
  );
}

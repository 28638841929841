import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import ErrorInputMessage from './generic/ErrorInputMessage';
import Validators from './../validators/validators';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../stores/slices/userSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './../styles/components/LoginForm.scss';
import Auth from '../controllers/auth.controller';
import {
  confirmAction,
  DURATION_TOAST,
  showToast,
  TYPE_ICON,
} from '../helpers/sweetAlert.helper';
import { loginInstitution } from '../stores/slices/institutionSlice';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import { updateActiveStudent } from '../stores/slices/studentSlice';
import { setToken } from '../helpers/localstorage.helper';
import { loadCart } from './../stores/slices/carSlice';
import recaptchaValidateService from '../services/recaptchaValidate.service';
import { deleteDB } from './../db/IDB';
import { enableNotifications } from '../helpers/notifications.helper';

export default function LoginForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const isOnline = useSelector((state) => state.internet.isOnline);
  const [showPassword, setShowPassword] = useState(false);
  const [resetClicks, setResetClicks] = useState(0);

  const handleReCaptchaVerify = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('login');
      const {
        estatus,
        datos: { success },
      } = await recaptchaValidateService.validateToken(token);
      if (!estatus || !success) {
        showToast(
          'Error Captcha',
          'No es posible verificar el captcha, recargue la página por favor.',
          TYPE_ICON.WARNING
        );
        return false;
      }

      return true;
    } catch (error) {
      showToast(
        'Error',
        'No fue posible validar el captcha. Recargue la página e intente nuevamente',
        TYPE_ICON.ERROR
      );
    }
  }, [executeRecaptcha]);

  const onSubmit = async (data) => {
    try {
      dispatch(showLoader());

      if (isOnline) {
        const valid = await handleReCaptchaVerify();
        if (!valid) {
          dispatch(hideLoader());
          return;
        }
      }

      const auth = new Auth();
      const loginData = await auth.authenticate(
        data.usuario,
        data.password,
        isOnline
      );

      if (!loginData) {
        reset();
        dispatch(hideLoader());
        return;
      }
      const { padre_tutor, institucion, token } = loginData;
      const activeStudent = padre_tutor.alumnos[0];
      dispatch(login(padre_tutor));
      enableNotifications(padre_tutor.id);
      activeStudent && dispatch(updateActiveStudent(activeStudent));
      dispatch(loginInstitution(institucion));
      dispatch(loadCart());
      setToken(token?.token || '');
    } catch (e) {
      showToast(
        'Error',
        'No fue posible iniciar sesión, intente nuevamente por favor',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleForgotPassword = async () => {
    window.open(process.env.REACT_APP_URL_RESTORE_PASSWORD);
  };

  const deleteDataBase = async () => {
    if (resetClicks === 4) {
      setResetClicks(0);
      const confirmed = await confirmAction(
        'Confirmar acción',
        '¿Deseas borrar los datos de la aplicación?'
      );
      if (confirmed) {
        const promises = await deleteDB();
        await Promise.allSettled(promises);
        showToast(
          'Éxito',
          'Se han limpiado todos los datos de la aplicación',
          TYPE_ICON.INFO,
          DURATION_TOAST.INFINITE
        );
      }
      return;
    }
    setResetClicks(resetClicks + 1);
  };

  return (
    <div className="login-form-container flex-grow-1 d-flex justify-content-center align-items-center">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="arvo-font f-20px titles-login" onClick={deleteDataBase}>
          Inicio de sesión
        </h2>
        <p className="titles-login mb-5">
          Inicio de sesión para padres de familia
        </p>
        <div className="input-control txt-usuario">
          <input
            type="text"
            className={`d-block w-100 input-login ${
              errors.usuario ? 'has-error' : ''
            }`}
            placeholder="Usuario"
            {...register('usuario', {
              validate: (value) => Validators.Email(value),
            })}
          />
          <ErrorInputMessage
            show={errors.usuario}
            message="El usuario no tiene un formato válido"
            classes="error-inputform-message"
          />
        </div>
        <div className="input-control txt-pass">
          <span
            className="show-password"
            onClick={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? (
              <i className="fas fa-eye"></i>
            ) : (
              <i className="far fa-eye-slash"></i>
            )}
          </span>
          <input
            type={showPassword ? 'text' : 'password'}
            className={`d-block w-100 input-login ${
              errors.password ? 'has-error' : ''
            }`}
            placeholder="Contraseña"
            {...register('password', { required: true })}
          />
          <ErrorInputMessage
            show={errors.password}
            message="Ingresa la contraseña"
            classes="error-inputform-message"
          />
        </div>
        <button
          type="submit"
          className="btn-login d-block btn font-bold mx-auto scale-hover"
        >
          Entrar
        </button>
        <span
          className="d-block forgot-password-link f-14px text-center cursor-pointer"
          onClick={handleForgotPassword}
        >
          Olvidé mi contraseña
        </span>
      </form>
    </div>
  );
}

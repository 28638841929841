import React, { useState } from 'react';
import UploadDocument from './UploadDocument';
import FileViewer from './generic/FileViewer';
import useDigitalFile from './../hooks/useDigitalFile';
import { useSelector } from 'react-redux';

export default function MandatoryDocuments() {
  const documents = useDigitalFile([]);
  const [previewFile, setPreviewFile] = useState(null);
  const { banderaCambios: allowModifications } = useSelector(
    (state) => state.institution.config
  );
  const activeStudent = useSelector((state) => state.student);
  return (
    <React.Fragment>
      <FileViewer file={previewFile} />
      <div className="mandatory-documents">
        <div className="mandatory-documents-container">
          {documents.map((document) =>
            document.obligatorio ? (
              <UploadDocument
                document={document}
                key={`${document.idDocumento}-${document.idAlumno}`}
                preview={setPreviewFile}
                allowModifications={allowModifications ?? false}
                activeStudent={activeStudent}
              />
            ) : null
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

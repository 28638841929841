import BaseModel from './base.model';

class Institution extends BaseModel {
  constructor(email, secretKey) {
    super(email, secretKey, true);
  }

  addInstitution(institution) {
    return this.db.institution.add(institution);
  }

  putInstitution(institution) {
    return this.db.institution.put(institution);
  }

  readInstitution(id) {
    return this.db.institution.where({ id }).first();
  }

  readInstitutions() {
    return this.db.institution.toArray();
  }

  putSchoolarships(schoolarships) {
    return this.db.schoolarships.put(schoolarships);
  }

  readSchoolarships(id) {
    // TODO: Return only last convocatoria
    return this.db.schoolarships.where({ idInstitucion: id }).first();
  }

  updateSchoolarShipFile(data) {
    return this.db.schoolarships_files.put(data);
  }

  async readSchoolarshipFile(id, idDocument) {
    const register = await this.db.schoolarships_files
      .where({ idInstitucion: id, idDocumento: idDocument })
      .first();
    return register?.file;
  }

  async readConfig(id) {
    const data = await this.readInstitution(id);
    return data?.config;
  }

  updateConfig(id, config) {
    return this.db.institution.where({ id }).modify({ config });
  }

  putNormateca(normateca) {
    return this.db.transaction('rw', [this.db.normateca], async () => {
      await this.db.normateca.bulkPut(normateca);
    });
  }

  readNormateca(id) {
    return this.db.normateca.where({ idInstitucion: id }).toArray();
  }

  updateNormatecaFile(data) {
    return this.db.normateca_files.put(data);
  }

  async readNormatecaFile(id, idDocument) {
    const register = await this.db.normateca_files
      .where({ idInstitucion: id, idDocumento: idDocument })
      .first();
    return register?.file;
  }
}

export default Institution;

import baseService from './base.service';
import Config from './../config/general-config';
import wsResponseHandler from './../handlers/wsResponse.hanlder';
import { getToken } from '../helpers/localstorage.helper';

const fileService = {
  getDocument: async (idFile) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.GET_FILE,
      {
        _idArchivo: idFile,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
};

export default fileService;

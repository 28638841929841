import React, { useState } from 'react';
import './../styles/components/SchoolarshipsOptions.scss';
import { ReactComponent as SchoolarshipInfoIcon } from './../assets/imgs/beca_info.svg';
import FileViewer from './generic/FileViewer';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import InstitutionController from './../controllers/institution.controller';
import fileService from '../services/file.service';
import schoolarshipsService from '../services/schoolarships.service';
import {
  showToast,
  TYPE_ICON,
  DURATION_TOAST,
} from '../helpers/sweetAlert.helper';
import SchoolarshipsTypes from './SchoolarshipsTypes';

export default function SchoolarshipsOptions({ schoolarships }) {
  const [previewFile, setPreviewFile] = useState(null);
  const dispatch = useDispatch();
  const { isOnline } = useSelector((state) => state.internet);
  const user = useSelector((state) => state.user);
  const institution = useSelector((state) => state.institution);
  const student = useSelector((state) => state.student);
  const [showTypesModal, setShowTypesModal] = useState(false);
  const [schoolarshipsTypes, setSchoolarshipsTypes] = useState([]);
  const [startProcess, setStartProcess] = useState(false);

  const {
    nombreConvocatoria: name,
    descripcionConvocatoria: description,
    documentos: documents,
  } = schoolarships;

  const handleClick = async (data) => {
    try {
      dispatch(showLoader());

      const schoolarshipsController = new InstitutionController(
        user.email,
        user.keyDecrypt
      );
      let _file = null;
      if (isOnline) {
        const response = await fileService.getDocument(data.idArchivo);
        if (!response) {
          showToast(
            'Documento no encontrado',
            'No se ha encontrado el documento en el servidor o no ha sido cargado',
            TYPE_ICON.WARNING
          );
          return;
        }
        _file = {
          name: data.nombreDocumento,
          fileData: response.archivoBytes,
          mimeType: response.tipoArchivo,
        };
        await schoolarshipsController.updateSchoolarShipFile({
          idInstitucion: institution.id,
          idDocumento: data.idArchivo,
          file: _file,
        });
      } else {
        _file = await schoolarshipsController.readSchoolarshipFile(
          institution.id,
          data.idArchivo
        );
        if (!_file) {
          showToast(
            'Documento no almacenado localmente',
            'No se cuenta con una copia local del archivo para visualizarse sin conexión. Las copias locales se almacenan cuando el archivo se visualiza al menos una vez con conexión a internet activa',
            TYPE_ICON.INFO,
            DURATION_TOAST.INFINITE
          );
          return;
        }
      }
      if (_file) {
        setPreviewFile(_file);
      }
    } catch (e) {
      showToast(
        'Error cargando documento',
        'No se ha podido cargar el documento. Recargue la página e intente nuevamente por favor',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const showSchoolarshipTypes = async () => {
    try {
      dispatch(showLoader());
      const response = await schoolarshipsService.getTypes(
        institution.id,
        student.id
      );
      if (!response) {
        throw new Error('No se han podido cargar los tipo de becas');
      }
      if (typeof response === 'string') {
        return;
      }
      setSchoolarshipsTypes(response);
      setShowTypesModal(true);
    } catch (e) {
      showToast(
        'Error',
        'No se han podido cargar los tipo de becas',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const requestSchoolarShip = () => {
    setStartProcess(true);
    showSchoolarshipTypes();
  };

  const handleCloseModal = () => {
    setStartProcess(false);
    setShowTypesModal(false);
  };

  return (
    <React.Fragment>
      <FileViewer file={previewFile} />
      <SchoolarshipsTypes
        showModal={showTypesModal}
        onClose={handleCloseModal}
        types={schoolarshipsTypes}
        startProcess={startProcess}
      ></SchoolarshipsTypes>
      <div className="schoolarships-options">
        <div className="schoolarships-options-container d-flex">
          {documents &&
            documents.map((document) => {
              return (
                <div
                  className="schoolarship-option"
                  onClick={() => handleClick(document)}
                  key={document.idArchivo}
                >
                  <SchoolarshipInfoIcon />
                  <span>
                    {document.nombreDocumento === 'Convocatoria'
                      ? name
                      : document.nombreDocumento + ' ' + name}
                  </span>
                  <small className="schoolarship-description f-10px">
                    {document.nombreDocumento === 'Convocatoria'
                      ? description
                      : 'Resultados ' + description}
                  </small>
                </div>
              );
            })}
          <div className="schoolarship-option" onClick={showSchoolarshipTypes}>
            <SchoolarshipInfoIcon />
            <span>Tipos de beca</span>
            <small className="schoolarship-description f-10px">
              Aquí podrás consultar la información de los tipos de beca oferta
              la institutción
            </small>
          </div>
          {documents && (
            <div className="schoolarship-option" onClick={requestSchoolarShip}>
              <SchoolarshipInfoIcon />
              <span>Tramita tu beca</span>
              <small className="schoolarship-description f-10px">
                Inicia el trámite de tu beca
              </small>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

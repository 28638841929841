import React, { useCallback } from 'react';
import './../styles/components/Logout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../stores/slices/userSlice';
import { confirmAction } from '../helpers/sweetAlert.helper';
import { closeMenu } from '../stores/slices/menuSlice';

export default function Logout() {
  const isOnline = useSelector((state) => state.internet.isOnline);
  const dispatch = useDispatch();

  const closeSession = useCallback(async () => {
    const exit = await confirmAction(
      '¿Está seguro que desea salir?',
      'Su sesión será cerrada'
    );
    if (exit) {
      dispatch(closeMenu());
      dispatch(logout());
    }
  }, [dispatch]);

  return (
    <div className="logout cursor-pointer font-bold" onClick={closeSession}>
      <i
        className={
          'fa fa-power-off ' + (isOnline ? 'text-orange' : 'text-gray-3')
        }
      ></i>
    </div>
  );
}

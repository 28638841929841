import BaseModel from './base.model';

class SyncModel extends BaseModel {
  constructor(email, secretKey) {
    super(email, secretKey, true);
  }

  putAction(action) {
    return this.db.sync.add(action);
  }

  putUniqueAction(action) {
    const { idAlumno, tipo } = action;
    return this.db.transaction('rw', [this.db.sync], async () => {
      await this.db.sync.where({ idAlumno, tipo }).delete();
      await this.db.sync.add(action);
    });
  }

  readActions() {
    return this.db.sync.toArray();
  }

  deleteActionSynchronized(id) {
    return this.db.sync.delete(id);
  }
}

export default SyncModel;

import { useState, useEffect, useRef } from 'react';

export default function useClickOutsideComponent() {
  const [isClickOutside, setIsClickOutside] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    const isClickOutside = ref.current && !ref.current.contains(event.target);
    setIsClickOutside(isClickOutside);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isClickOutside, setIsClickOutside };
}

import React, { useState } from 'react';
import { getCurrentYear } from '../../utils/dates.util';
import logo from '../../assets/imgs/logo_sac_small.svg';
import './../../styles/components/Footer.scss';
import PrivacyNotice from './PrivacyNotice';
import version from '../../version';

export default function Footer() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <footer id="footer" className="d-none d-md-block">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <img className="logo-footer" src={logo} alt="SAC" />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-white font-bold f-12px">
            <span>
              <span>&copy;</span> {getCurrentYear()} - Todos los Derechos
              Reservados
            </span>
            <span className="text-orange-secondary">EMPRESA DE DESARROLLO</span>
          </div>
          <div className="f-10px text-end footer-version">
            <span className="d-block text-orange-secondary">
              Versión {version.VERSION_APP}
            </span>
            <span
              className="d-block text-orange-secondary cursor-pointer privacy-notice-text"
              onClick={handleShow}
            >
              Aviso de privacidad
            </span>
          </div>
        </div>
      </footer>
      <PrivacyNotice showModal={show} onClose={handleClose} />
    </React.Fragment>
  );
}

import React from 'react';
import TitleModule from '../core/TitleModule';
import { Container, Row, Col } from 'react-bootstrap';
import UploadPhotoDigitalFile from '../UploadPhotoDigitalFile';
import './../../styles/components/DigitalFile.scss';
import MandatoryDocuments from '../MandatoryDocuments';
import LockModifications from './../generic/LockModifications';
import SizeWeight from './../SizeWeight';

export default function DigitalFile() {
  return (
    <React.Fragment>
      <TitleModule title={'Expediente digital'} />
      <Container className="digital-file px-4 py-md-4">
        <Row className="mb-4">
          <Col xs={12}>
            <div className="file-description verdana-font text-center text-md-start">
              En esta sección se muestra el listado{' '}
              <b>documentos solicitados para alumnos</b> que conforman el{' '}
              <b>Expediente Digital</b>, con la posibilidad de agregar (
              <b>
                solo durante el periodo definido por la Institución Educativa
              </b>
              ): los documentos requeridos para validación de la Escuela, la
              fotografía, los datos de peso y talla del alumno; así como
              consultar el estatus de validación de los archivos cargados
              previamente.
            </div>
          </Col>
        </Row>
        <Row className="documents-container">
          <Col xs={12}>
            <LockModifications />
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <UploadPhotoDigitalFile />
          </Col>
          <Col xs={12} md={8}>
            <MandatoryDocuments />
          </Col>
          <hr className="my-4"></hr>
          <Col xs={12}>
            <SizeWeight />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

import baseService from './base.service';
import wsResponseHandler from './../handlers/wsResponse.hanlder';
import Config from './../config/general-config';
import { getToken } from '../helpers/localstorage.helper';

const normatecaService = {
  getNormatecaData: async (idInstitution) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.NORMATECA,
      {
        _idEscuela: idInstitution,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
};

export default normatecaService;

import React from 'react';
import './../../styles/components/HeaderDesktop.scss';
import { Row, Col } from 'react-bootstrap';
import StudentMenu from '../StudentMenu';
import Logout from './../Logout';
import Notifications from './../Notifications';
import Calendar from './../Calendar';
import '../../styles/components/Header.scss';
import LogoInstitution from '../LogoInstitution';
import Sync from '../Sync';

export default function HeaderDesktop() {
  return (
    <Row className="h-100">
      <Col
        xs={2}
        className="h-100 d-flex justify-content-center align-items-center"
      >
        <LogoInstitution />
      </Col>
      <Col xs={10} className="h-100">
        <div className="header-options-container h-100">
          <Sync />
          <Calendar />
          <Notifications />
          <StudentMenu />
          <Logout />
        </div>
      </Col>
    </Row>
  );
}

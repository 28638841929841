import React from 'react';
import { useSelector } from 'react-redux';
import './../../styles/components/ConnectionIndicator.scss';

export default function ConnectionIndicator() {
  const isOnline = useSelector((state) => state.internet.isOnline);
  return (
    <div className="connection-indicator d-flex align-content-center">
      <div
        className={`connection-indicator-container ${isOnline ? 'active' : ''}`}
      >
        <span className="arvo-font">{isOnline ? 'On line' : 'Off line'}</span>
        <span className="connection-indicator-circle"></span>
      </div>
    </div>
  );
}

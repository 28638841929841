import { showToast, TYPE_ICON } from './../helpers/sweetAlert.helper';

const wsResponseHandler = (response) => {
  const { estatus, mensaje, datos } = response;
  if (!estatus) {
    showToast('Error', mensaje, TYPE_ICON.ERROR);
    return false;
  }
  if (estatus && !datos) {
    return [];
  }
  return datos;
};

export default wsResponseHandler;

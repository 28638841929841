import BaseModel from './base.model';

class EducationalPathModel extends BaseModel {
  constructor(email, secretKey) {
    super(email, secretKey, true);
  }

  addPath(path, idStudent) {
    return this.db.transaction('rw', [this.db.path], async () => {
      await this.db.path.where('idAlumno').equals(idStudent).delete();
      await this.db.path.bulkAdd(path);
    });
  }

  readPath(idStudent) {
    return this.db.path.where('idAlumno').equals(idStudent).toArray();
  }

  addEvaluations(evaluations, indexes) {
    return this.db.transaction('rw', [this.db.evaluations], async () => {
      let keysToDelete = await this.db.evaluations.where(indexes).toArray();
      keysToDelete = keysToDelete.map((item) => item.id);
      await this.db.evaluations.bulkDelete(keysToDelete);
      await this.db.evaluations.bulkAdd(evaluations);
    });
  }

  readEvaluations(indexes) {
    return this.db.evaluations.where(indexes).toArray();
  }
}

export default EducationalPathModel;

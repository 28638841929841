import Menu from './../models/menu.model';

class MenuController {
  #model;

  constructor(email, secretKey) {
    this.#model = new Menu(email, secretKey);
  }

  updateMenu(menu) {
    return this.#model.updateMenu(menu);
  }

  readMenu() {
    return this.#model.readMenu();
  }
}

export default MenuController;

import React from 'react';
import { ReactComponent as NormatecaIcon } from './../assets/imgs/normateca.svg';
import './../styles/components/NormatecaOption.scss';
import { Link } from 'react-router-dom';

export default function NormatecaOption() {
  return (
    <div className="normateca-option scale-hover">
      <Link
        className="normateca-option-container cursor-pointer d-flex"
        to={'/normateca'}
      >
        <div className="normateca-option-icon-container">
          <NormatecaIcon />
        </div>
        <div className="normateca-option-title d-flex">
          <span className="font-bold text-orange arvo-font my-auto">
            Normateca
          </span>
        </div>
      </Link>
    </div>
  );
}

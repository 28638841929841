import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Board from '../components/pages/Board';
import NoticesRemainders from '../components/pages/NoticesRemainders';
import Tasks from '../components/pages/Tasks';
import EducationalPath from '../components/pages/EducationalPath';
import DigitalFile from '../components/pages/DigitalFile';
import Schoolarships from '../components/pages/Schoolarships';
import Store from '../components/pages/Store';
import OrderDetail from '../components/pages/OrderDetail';
import Orders from '../components/pages/Orders';
import Procedures from '../components/pages/Procedures';
import RequestProcedure from '../components/pages/RequestProcedure';
import Normateca from '../components/pages/Normateca';
import RequestSchoolarship from '../components/pages/RequestSchoolarship';

export default function PrivateRouter() {
  return (
    <Routes>
      <Route path="/" element={<Board />}></Route>
      <Route
        path="/avisos-notificaciones"
        element={<NoticesRemainders />}
      ></Route>
      <Route path="/becas" element={<Schoolarships />}></Route>
      <Route path="/carrito-compras" element={<OrderDetail />}></Route>
      <Route path="/expediente" element={<DigitalFile />}></Route>
      <Route path="/normateca" element={<Normateca />}></Route>
      <Route path="/pedidos" element={<Orders />}></Route>
      <Route path="/info-tramite/:id" element={<RequestProcedure />}></Route>
      <Route
        path="/solicitar-beca/:id"
        element={<RequestSchoolarship />}
      ></Route>
      <Route
        path="/solicitar-tramite/:id"
        element={<RequestProcedure editMode={true} />}
      ></Route>
      <Route path="/tareas" element={<Tasks />}></Route>
      <Route path="/trayectoria" element={<EducationalPath />}></Route>
      <Route path="/tramites-servicios" element={<Procedures />}></Route>
      <Route path="/uniformes" element={<Store />}></Route>
      <Route path="/*" element={<Navigate to="/" />}></Route>
    </Routes>
  );
}

import React, { useRef } from 'react';
import '../styles/components/ProcedureDocument.scss';
import CustomTooltip from './generic/CustomTooltip';
import eyeIcon from '../assets/imgs/eye.svg';
import cloudIcon from '../assets/imgs/upload.svg';
import Config from '../config/general-config';
import {
  confirmAction,
  showToast,
  TYPE_ICON,
} from '../helpers/sweetAlert.helper';
import { getExtentionFromMimeType, calculateSizeOfFile } from '../utils/utils';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import ProceduresService from '../services/procedures.service';

export default function ProcedureDocument({
  idRequest,
  document,
  allowUpload,
  handlePreview,
  onUploadFile,
}) {
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const handleUpload = () => {
    inputRef.current?.click();
  };
  const resetFileInput = () => {
    inputRef.current.value = null;
  };

  const uploadFile = async (event) => {
    const docFile = inputRef.current.files[0];
    if (!docFile) {
      return;
    }
    const confirm = await confirmAction(
      'Subir documento',
      `El documento <i><strong>${docFile.name}</strong></i> será cargado. ¿Desea continuar?`
    );
    if (!confirm) {
      return;
    }
    if (
      !Config.validDocumentsExtentions.includes(
        getExtentionFromMimeType(docFile.type)
      )
    ) {
      showToast(
        'Tipo de archivo inválido',
        'El tipo de archivo seleccionado no es válido',
        TYPE_ICON.WARNING
      );
      resetFileInput();
      return;
    }
    if (calculateSizeOfFile(docFile.size, 'MB') > Config.MAX_DOC_SIZE_MB) {
      showToast(
        'Archivo inválido',
        `El archivo seleccionado tiene un peso mayor a ${Config.MAX_DOC_SIZE_MB} Mb`,
        TYPE_ICON.WARNING
      );
      resetFileInput();
      return;
    }

    try {
      dispatch(showLoader());
      const formData = new FormData();
      formData.append('doc', docFile);
      const idUploadedFile = await ProceduresService.uploadDocument(
        idRequest,
        document.idDocumento,
        formData
      );
      if (!idUploadedFile) {
        throw new Error('Ha ocurrido un error al cargar el documento');
      }
      document.idArchivoCargado = idUploadedFile;
      onUploadFile();
      showToast('Éxito', 'Archivo cargado exitósamente', TYPE_ICON.SUCCESS);
    } catch (e) {
      console.log(e);
      showToast(
        'Error',
        'No se ha podido cargar el documento. Intente nuevamente por favor.',
        TYPE_ICON.ERROR
      );
    } finally {
      resetFileInput();
      dispatch(hideLoader());
    }
  };

  return (
    <div className="procedure-document">
      <div className="procedure-document-container">
        <div className="procedure-document-actions">
          <div className="procedure-document-icons">
            <CustomTooltip
              text={
                document.idArchivoCargado
                  ? 'Documento cargado'
                  : 'Documento no cargado'
              }
            >
              <span className="file-status">
                {document.idArchivoCargado ? (
                  <i className="far fa-check-circle fa-2x"></i>
                ) : (
                  <i className="far fa-times-circle fa-2x"></i>
                )}
              </span>
            </CustomTooltip>
            {document.idArchivoFormato && (
              <CustomTooltip text="Descargar formato">
                <span
                  className="text-primary cursor-pointer"
                  onClick={() => handlePreview(document.idArchivoFormato)}
                >
                  <i className="fas fa-file-download fa-2x"></i>
                </span>
              </CustomTooltip>
            )}
            {document.idArchivoCargado && (
              <CustomTooltip text="Previsualizar archivo cargado">
                <div
                  className="procedure-document-preview cursor-pointer"
                  onClick={() => handlePreview(document.idArchivoCargado)}
                >
                  <img src={eyeIcon} alt="Previsualizar" />
                </div>
              </CustomTooltip>
            )}
            {allowUpload && (
              <CustomTooltip text="Seleccionar archivo para carga">
                <div className="custom-file-input-container cursor-pointer">
                  <img
                    src={cloudIcon}
                    alt="Seleccionar Archivo"
                    onClick={handleUpload}
                  />
                  <input
                    type="file"
                    name=""
                    id=""
                    hidden
                    ref={inputRef}
                    accept={Config.validDocumentsExtentions}
                    onChange={uploadFile}
                  />
                </div>
              </CustomTooltip>
            )}
          </div>
          <div className="procedure-document-name-description">
            <label className="file-name d-block monserrat-font text-primary">
              {document.nombreDocumento}
            </label>
            <small className="f-10px text-gray-6">
              {document.indicaciones}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

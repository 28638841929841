import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import ProcedureDocument from './ProcedureDocument';
import FileViewer from './generic/FileViewer';
import fileService from '../services/file.service';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import { useDispatch } from 'react-redux';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';

export default function ProcedureDocuments({
  idRequest,
  documents,
  onUploadFile,
  allowUpload = false,
}) {
  const [previewFile, setPreviewFile] = useState(null);
  const dispatch = useDispatch();
  const handlePreview = async (id) => {
    try {
      dispatch(showLoader());
      let _previewFile = await fileService.getDocument(id);
      _previewFile = {
        fileData: _previewFile.archivoBytes,
        mimeType: _previewFile.tipoArchivo,
      };
      setPreviewFile(_previewFile);
    } catch (e) {
      showToast(
        'Error',
        'No se ha podido cargar el archivo para su previsualización',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <React.Fragment>
      <FileViewer file={previewFile}></FileViewer>
      <div className="procedure-documents mt-4">
        <Row className="mb-4">
          <Col xs={12}>
            <p className="text-primary text-uppercase">Documentos Necesarios</p>
          </Col>
        </Row>
        <Row>
          {documents &&
            documents.map((document) => (
              <Col xs={12} lg={6} key={document.idDocumento}>
                <ProcedureDocument
                  idRequest={idRequest}
                  document={document}
                  allowUpload={allowUpload}
                  handlePreview={handlePreview}
                  onUploadFile={onUploadFile}
                ></ProcedureDocument>
              </Col>
            ))}
          {documents.length === 0 && (
            <Col xs={12} className="text-center">
              <h5 className="text-gray-3">No se requieren documentos</h5>
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
  );
}

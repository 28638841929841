import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { online, offline } from '../stores/slices/internetSlice';

const useInternet = () => {
  const dispatch = useDispatch();
  const internet = useSelector((state) => state.internet);

  useEffect(() => {
    const handleOnline = () => dispatch(online());

    const handleOffline = () => dispatch(offline());

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return function cleanUp() {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [dispatch]);

  useEffect(() => {
    if (window.navigator.onLine && !internet.isOnline) {
      dispatch(online());
    } else if (!window.navigator.onLine && internet.isOnline) {
      dispatch(offline());
    }
  }, [dispatch, internet.isOnline]);

  return internet;
};

export default useInternet;

import React from 'react';
import HamburguerMenu from '../generic/HamburguerMenu';
import './../../styles/components/SideMenuHeader.scss';

import sacLogo from './../../assets/imgs/logo_sac_mid_white.svg';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleOpen } from '../../stores/slices/menuSlice';

export default function SideMenuHeader() {
  const dispatch = useDispatch();
  return (
    <div className="side-menu-header bg-orange text-white d-flex flex-row">
      <div className="side-menu-icon d-flex align-items-center">
        <HamburguerMenu />
      </div>
      <Link
        className="side-menu-logo-container"
        to="/"
        onClick={() => {
          dispatch(toggleOpen());
        }}
      >
        <img src={sacLogo} alt="SAC Menú Logo" className="side-menu-logo" />
      </Link>
    </div>
  );
}

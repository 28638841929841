import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import BaseModel from './base.model';

class User extends BaseModel {
  #email;

  constructor(email, secretKey) {
    super(email, secretKey);
    this.#email = email;
  }

  addUser(user) {
    try {
      return this.db.user.add(user);
    } catch (e) {
      showToast(
        'Error',
        'No se ha podido guardar la información del usuario. Contacte al administrador',
        TYPE_ICON.ERROR
      );
    }
  }

  readUser() {
    try {
      return this.db.user.where('email').equals(this.#email).first();
    } catch (e) {
      showToast(
        'Error',
        'No se ha podido leer la información del usuario. Contacte al administrador',
        TYPE_ICON.ERROR
      );
    }
  }
}

export default User;

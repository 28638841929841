import React from 'react';
import '../styles/components/OrderConfirmed.scss';
import { spanishFormattedDate } from './../utils/dates.util';
import { numberMoreReadeable } from '../utils/utils';

export default function OrderConfirmed({ order }) {
  return (
    <div className="order-confirmed">
      <div className="order-confirmed-detail mt-4">
        <div className="order-header d-flex flex-column">
          <div className="text-bold f-20px first-uppercase">
            {spanishFormattedDate(order.fecha)}
          </div>
          <div className="f-18px">
            <strong>Pedido: {order.idSolicitud}</strong>
          </div>
          <small>
            <strong>Estatus:</strong> {order.estatus}
          </small>
          <small>
            <strong>Detalle:</strong> {order.servicio}
          </small>
          <small>
            <strong>Total:</strong> $ {numberMoreReadeable(order.total)}
          </small>
        </div>
        <hr></hr>
        <div className="order-products mt-3">
          <table className="table-products w-100">
            <thead>
              <tr>
                <th className="thead-order-detail d-none d-md-table-cell">
                  Artículo
                </th>
                <th className="thead-order-detail">Descripción</th>
                <th className="thead-order-detail">Imagen</th>
                <th className="thead-order-detail d-none d-md-table-cell">
                  Cantidad
                </th>
                <th className="thead-order-detail">Precio unitario</th>
              </tr>
            </thead>
            <tbody>
              {order?.detalle &&
                order.detalle.map((product) => (
                  <tr key={product.idArticulo}>
                    <td className="text-center d-none d-md-table-cell">
                      {product.articulo}
                    </td>
                    <td className="text-center">{product.descripcion}</td>
                    <td className="text-center td-img-product">
                      <img
                        className="img-product"
                        src={product.foto}
                        alt={product.articulo}
                      />
                    </td>
                    <td className="text-center d-none d-md-table-cell">
                      {product.cantidad}
                    </td>
                    <td className="">
                      {numberMoreReadeable(product.precio, true)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

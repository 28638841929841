import React, { useState, useEffect } from 'react';
import { numberMoreReadeable, orderObjectsArray } from '../utils/utils';
import { deleteProduct } from './../stores/slices/carSlice';
import { useSelector, useDispatch } from 'react-redux';
import './../styles/components/TableProductsOrder.scss';
import PayOrder from './PayOrder';

const mapProducts = (products) => {
  let productsMap = new Map();
  products.forEach((product) => {
    let productInmap = productsMap.get(product.idArticulo);
    if (productInmap) {
      productInmap.cantidad = productInmap.cantidad + 1;
    } else {
      productsMap.set(product.idArticulo, { ...product, cantidad: 1 });
    }
  });
  return productsMap.values();
};

const calculateTotal = (products) => {
  return products.reduce(
    (total, product) => total + product.cantidad * product.artPrecioUnitario,
    0
  );
};

export default function TableProductsOrder() {
  const products = useSelector((state) => state.cart.products);
  const [mappedProducts, setMappedProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    let _products = mapProducts(products);
    _products = [..._products];
    orderObjectsArray(_products, 'artDescripcion');
    setTotal(calculateTotal(_products));
    setMappedProducts(_products);
  }, [products]);

  return mappedProducts.length === 0 ? (
    <div className="d-flex align-items-center justify-content-center">
      <div className="no-products d-flex flex-column align-items-center">
        <i className="fa fa-shopping-cart no-products-icon"></i>
        <span className="no-products-label font-bold f-18px">
          El carrito de compras se encuentra vacío
        </span>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <table className="table-products mx-auto">
        <thead>
          <tr>
            <th className="thead-order-detail d-none d-md-table-cell">
              Artículo
            </th>
            <th className="thead-order-detail">Descripción</th>
            <th className="thead-order-detail">Imagen</th>
            <th className="thead-order-detail d-none d-md-table-cell">
              Cantidad
            </th>
            <th className="thead-order-detail">Precio unitario</th>
            <th className="thead-order-options">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {mappedProducts.map((product) => (
            <tr key={product.idArticulo}>
              <td className="text-center d-none d-md-table-cell">
                {product.articulo}
              </td>
              <td className="text-center">{product.artDescripcion}</td>
              <td className="text-center td-img-product">
                <img
                  className="img-product"
                  src={product.fotoBase64}
                  alt={product.articulo}
                />
              </td>
              <td className="text-center d-none d-md-table-cell">
                {product.cantidad}
              </td>
              <td className="">
                {numberMoreReadeable(product.artPrecioUnitario, true)}
              </td>
              <td className="text-center">
                <i
                  className="fa fa-times-circle text-danger cursor-pointer"
                  onClick={() => dispatch(deleteProduct(product.idArticulo))}
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className="d-none d-md-table-cell"></td>
            <td colSpan={2}></td>
            <td className="bg-orange text-white font-bold text-center text-uppercase p-2">
              TOTAL:
            </td>
            <td colSpan={2} className="bg-white p-2">
              {numberMoreReadeable(total, true)}
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="order-options d-block d-md-flex justify-content-end mt-3">
        <div className="total-mobile d-flex d-md-none w-100">
          <div className="total-label bg-orange text-white font-bold text-center text-uppercase w-50 p-2">
            TOTAL:
          </div>
          <div className="total-order bg-white w-50 p-2">
            {numberMoreReadeable(total, true)}
          </div>
        </div>
        <PayOrder order={mappedProducts} />
      </div>
    </React.Fragment>
  );
}

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import InstitutionController from './../controllers/institution.controller';
import normatecaService from './../services/normateca.service';

const useSchoolarShips = (initialValue) => {
  const { isOnline } = useSelector((state) => state.internet);
  const [normateca, setNormateca] = useState(initialValue ?? []);
  const institution = useSelector((state) => state.institution);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const institutionController = new InstitutionController(
      user.email,
      user.keyDecrypt
    );
    const fetchFiles = async () => {
      try {
        if (!institution) return;
        let files = await normatecaService.getNormatecaData(institution.id);
        if (!files) {
          dispatch(hideLoader());
          return;
        }

        const normatecaFiles = files.normateca.map((file) => ({
          ...file,
          type: 'NORMATECA',
        }));
        const convivenciaFiles = files.convivencia.map((file) => ({
          ...file,
          type: 'CONVIVENCIA',
        }));
        const mappedFiles = [...normatecaFiles, ...convivenciaFiles];

        await institutionController.putNormateca(institution.id, mappedFiles);
        files = {
          files: mappedFiles,
          interes: files.interes,
        };
        setNormateca(files);
      } catch (e) {
        showToast(
          'Error',
          'No se ha podido leer la información de becas',
          TYPE_ICON.ERROR
        );
      } finally {
        dispatch(hideLoader());
      }
    };

    dispatch(showLoader());
    if (isOnline) {
      fetchFiles();
    } else {
      institutionController
        .readNormateca(institution.id)
        .then((files) => {
          if (files.length === 0 && !isOnline) {
            showToast(
              'Conexión necesaria',
              'Para llevar a cabo la primera descarga de datos es necesaria una conexión a internet. Intente nuevamente una vez que su dispositivo tenga conexión.',
              TYPE_ICON.ERROR
            );
          } else {
            files = {
              files: [...files],
              interes: [],
            };
            setNormateca(files);
          }
        })
        .catch((e) =>
          showToast(
            'Error',
            'No se ha podido leer la información de la Normateca',
            TYPE_ICON.ERROR
          )
        )
        .finally(() => {
          dispatch(hideLoader());
        });
    }
  }, [institution, dispatch, user.email, user.keyDecrypt, isOnline]);

  return normateca;
};

export default useSchoolarShips;

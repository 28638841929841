import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  name: null,
  avatar: null,
};

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    updateActiveStudent: (state, action) => {
      const { idAlumno, nombre, primerApellido, segundoApellido, fotoBase64 } =
        action.payload;
      state.id = idAlumno;
      state.name = `${nombre} ${primerApellido ?? ''} ${segundoApellido ?? ''}`;
      state.avatar = fotoBase64;
    },
    updatePhoto: (state, action) => {
      state.avatar = action.payload;
    },
  },
});

export const { updateActiveStudent, updatePhoto } = studentSlice.actions;
export default studentSlice.reducer;

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import educationalPathService from './../services/educationalPath.service';
import EducationalPathController from './../controllers/educationlPath.controller';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';

const useEvaluations = (idEvaluationTime, grade, initialValue) => {
  const { isOnline } = useSelector((state) => state.internet);
  const [evaluations, setEvaluations] = useState(initialValue ?? []);
  const activeStudent = useSelector((state) => state.student);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // idAlumno*, idPeriodo*, idEscuela, idGrado, idCiclo, idNivel

  useEffect(() => {
    const educationalPathController = new EducationalPathController(
      user.email,
      user.keyDecrypt
    );

    if (!activeStudent.id || !grade) return;
    const indexes = {
      idAlumno: activeStudent.id,
      idPeriodo: idEvaluationTime,
      idEscuela: grade.idEscuela,
      idGrado: grade.idGrado,
      idNivel: grade.idNivel,
      idCiclo: grade.idCicloEscolar,
    };

    const fetchEvaluations = async () => {
      try {
        let _evaluations = await educationalPathService.getEvaluations(indexes);
        if (!_evaluations) {
          dispatch(hideLoader());
          return;
        }
        _evaluations = _evaluations.map((item) => ({
          ...item,
          ...indexes,
        }));
        await educationalPathController.addEvaluations(_evaluations, indexes);
        setEvaluations(_evaluations);
      } catch (e) {
        showToast(
          'Información no disponible',
          'No se ha podido recuperar la información de las evaluaciones. Intente más tarde',
          TYPE_ICON.WARNING
        );
      } finally {
        dispatch(hideLoader());
      }
    };

    dispatch(showLoader());

    if (isOnline) {
      fetchEvaluations();
    } else {
      educationalPathController
        .readEvaluations(indexes)
        .then((path) => {
          if (!activeStudent.id || !grade) return;
          if (path.length === 0 && !isOnline) {
            showToast(
              'Conexión necesaria',
              'Para llevar a cabo la primera descarga de datos es necesaria una conexión a internet. Intente nuevamente una vez que su dispositivo tenga conexión.',
              TYPE_ICON.WARNING
            );
          } else if (path.length === 0 && isOnline) {
            fetchEvaluations();
          } else {
            setEvaluations(path);
          }
        })
        .catch((e) =>
          showToast(
            'Error',
            'No se ha podido leer la información de la trayectoria educativa',
            TYPE_ICON.ERROR
          )
        )
        .finally(() => {
          dispatch(hideLoader());
        });
    }
  }, [
    activeStudent.id,
    dispatch,
    user.email,
    user.keyDecrypt,
    isOnline,
    idEvaluationTime,
    grade,
    grade?.idEscuela,
    grade?.idGrado,
    grade?.idNivel,
    grade?.idCicloEscolar,
  ]);

  return evaluations;
};

export default useEvaluations;

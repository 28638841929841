import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import DigitalFileController from './../controllers/digitalFile.controller';
import digitalFileService from './../services/digitalFile.service';

const useDigitalFile = (initialValue) => {
  const { isOnline } = useSelector((state) => state.internet);
  const [digitalFile, setDigitalFile] = useState(initialValue ?? []);
  const activeStudent = useSelector((state) => state.student);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const digitalFileController = new DigitalFileController(
      user.email,
      user.keyDecrypt
    );
    const fetchFiles = async () => {
      if (!activeStudent.id) return;
      let files = await digitalFileService.getDigitalFile(activeStudent.id);
      if (!files) {
        dispatch(hideLoader());
        return;
      }

      files = files.expedienteDigital;
      digitalFileController.putDigitalFile(files, activeStudent.id);
      files = files.map((file) => ({
        ...file,
        idAlumno: activeStudent.id,
      }));
      setDigitalFile(files);
      dispatch(hideLoader());
    };

    dispatch(showLoader());

    if (isOnline) {
      fetchFiles();
    } else {
      digitalFileController
        .readDigitalFile(activeStudent.id)
        .then((files) => {
          if (files.length === 0 && !isOnline) {
            showToast(
              'Conexión necesaria',
              'Para llevar a cabo la primera descarga de datos es necesaria una conexión a internet. Intente nuevamente una vez que su dispositivo tenga conexión.',
              TYPE_ICON.WARNING
            );
          } else if (files.length === 0 && isOnline) {
            fetchFiles();
          } else {
            setDigitalFile(files);
          }
        })
        .catch((e) =>
          showToast(
            'Error',
            'No se ha podido leer la información del expediente digital',
            TYPE_ICON.ERROR
          )
        )
        .finally(() => {
          dispatch(hideLoader());
        });
    }
  }, [activeStudent.id, dispatch, user.email, user.keyDecrypt, isOnline]);

  return digitalFile;
};

export default useDigitalFile;

import React from 'react';
import { spanishFormattedDate } from '../../utils/dates.util';

export default function DateFormatted() {
  return (
    <div className="date-formatted font-italic text-primary text-center text-md-start f-19px first-uppercase">
      {spanishFormattedDate()}
    </div>
  );
}

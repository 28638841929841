import axios from 'axios';
import { AxiosErrorHandler } from '../handlers/axiosError.handler';

const baseService = {
  getRequest: (endpoint, params = {}, headers = {}) => {
    return axios
      .get(endpoint, {
        headers,
        params,
      })
      .then((response) => response.data)
      .catch(AxiosErrorHandler);
  },
  postRequest: (endpoint, data, config) => {
    return axios
      .post(endpoint, data, config)
      .then((response) => response.data)
      .catch(AxiosErrorHandler);
  },
};

export default baseService;

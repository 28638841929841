import { cryptoOptions } from 'dexie-encrypted';

const IDBStoresUser = {
  user: 'id, email',
};

const IDBEncryptedStoresUser = {
  user: cryptoOptions.NON_INDEXED_FIELDS,
};

const IDBStoresData = {
  institution: 'id',
  student: 'idAlumno, idTutor',
  menu: 'id++',
  path: 'id++, idAlumno',
  digitalFile: '&[idAlumno+idDocumento], idAlumno, idDocumento',
  digitalFile_files: '&[idAlumno+idDocumento], idAlumno, idDocumento',
  sync: 'id++, [idAlumno+tipo], idAlumno, tipo',
  notifications:
    '&[idNotificacion+idUsuario+idAlumno], idNotificacion, idUsuario, idAlumno',
  schoolarships:
    '&[idInstitucion+idConvocatoria], idInstitucion, idConvocatoria',
  schoolarships_files:
    '&[idInstitucion+idDocumento], idInstitucion, idDocumento',
  normateca: '&[idInstitucion+idDocumento], idInstitucion, idDocumento',
  normateca_files: '&[idInstitucion+idDocumento], idInstitucion, idDocumento',
};

const IDBStoresData_v2 = {
  evaluations:
    'id++, idAlumno, idPeriodo, idGrado, idCiclo, idNivel, idEscuela',
};

const IDBEncryptedStoresData = {
  institution: cryptoOptions.NON_INDEXED_FIELDS,
  student: cryptoOptions.NON_INDEXED_FIELDS,
  menu: cryptoOptions.NON_INDEXED_FIELDS,
  path: cryptoOptions.NON_INDEXED_FIELDS,
  evaluations: cryptoOptions.NON_INDEXED_FIELDS,
  digitalFile: cryptoOptions.NON_INDEXED_FIELDS,
  digitalFile_files: cryptoOptions.NON_INDEXED_FIELDS,
  sync: cryptoOptions.NON_INDEXED_FIELDS,
  notifications: cryptoOptions.NON_INDEXED_FIELDS,
  schoolarships: cryptoOptions.NON_INDEXED_FIELDS,
  schoolarships_files: cryptoOptions.NON_INDEXED_FIELDS,
  normateca: cryptoOptions.NON_INDEXED_FIELDS,
  normateca_files: cryptoOptions.NON_INDEXED_FIELDS,
};

export {
  IDBStoresUser,
  IDBEncryptedStoresUser,
  IDBStoresData,
  IDBEncryptedStoresData,
  IDBStoresData_v2,
};

import { createSlice } from '@reduxjs/toolkit';
import {
  cleanShoppingCart,
  getShoppingCar,
  setShoppingCar,
} from './../../helpers/localstorage.helper';

const initialState = {
  products: getShoppingCar() ?? [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    loadCart: (state) => {
      state.products = getShoppingCar() ?? [];
    },
    addProduct: (state, action) => {
      const product = action.payload;
      const products = [...state.products, product];
      setShoppingCar(products);
      state.products = products;
    },
    deleteProduct: (state, action) => {
      const idProduct = action.payload;
      const indexProduct = state.products.findIndex(
        (product) => product.idArticulo === idProduct
      );
      const products = [...state.products];
      products.splice(indexProduct, 1);
      setShoppingCar(products);
      state.products = products;
    },
    cleanCart: (state, action) => {
      cleanShoppingCart();
      state.products = [];
    },
  },
});

export const { addProduct, deleteProduct, loadCart, cleanCart } =
  cartSlice.actions;
export default cartSlice.reducer;

import baseService from './base.service';
import Config from '../config/general-config';
import wsResponseHandler from '../handlers/wsResponse.hanlder';
import { getToken } from '../helpers/localstorage.helper';

const storeService = {
  listProducts: async (idEscuela) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.LIST_PRODUCTS,
      {
        _idEscuela: idEscuela,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  generateOrder: async (iPadre, orderData) => {
    const response = await baseService.postRequest(
      `${Config.ENDPOINTS.GENERATE_ORDER}?_idPadre=${iPadre}`,
      JSON.stringify(orderData),
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return wsResponseHandler(response);
  },
  listOrders: async (idPadre) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.LIST_ORDERS,
      {
        _idPadre: idPadre,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
};

export default storeService;

import React from 'react';
import './../styles/components/StoreOptions.scss';
import { Link } from 'react-router-dom';
import ShoppingCar from './ShoppingCar';

export default function StoreOptions() {
  return (
    <div className="store-options">
      <div className="store-options-container d-flex mt-3 align-items-center justify-content-center justify-content-md-end">
        <div className="order-status me-3">
          <Link
            className="order-link text-primary monserrat-bold-font"
            to={'/pedidos'}
          >
            Ver estatus del pedido
          </Link>
        </div>
        <ShoppingCar />
      </div>
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  nombre: null,
  clave: null,
  logo: null,
  config: { banderaCambios: false },
};

export const institutionSlice = createSlice({
  name: 'institution',
  initialState,
  reducers: {
    loginInstitution: (state, action) => {
      const { id, nombre, clave, logoBase64 } = action.payload;
      state.id = id;
      state.nombre = nombre;
      state.clave = clave;
      state.logo = logoBase64;
    },
    logoutInstitution: (state) => {
      state.id = null;
      state.nombre = null;
      state.clave = null;
      state.logo = null;
      state.config = { banderaCambios: false };
    },
    updateConfig: (state, action) => {
      state.config = action.payload;
    },
  },
});

export const { loginInstitution, logoutInstitution, updateConfig } =
  institutionSlice.actions;
export default institutionSlice.reducer;

import React, { useEffect, useState } from 'react';
import storeService from '../../services/store.service';
import { useSelector, useDispatch } from 'react-redux';
import TitleModule from './../core/TitleModule';
import { Container } from 'react-bootstrap';
import OrderConfirmed from '../OrderConfirmed';
import { showLoader, hideLoader } from '../../stores/slices/loaderSlice';
import { orderObjectsArray } from './../../utils/utils';
import OnlineContent from '../core/OnlineContent';
import { showToast, TYPE_ICON } from '../../helpers/sweetAlert.helper';

export default function Orders() {
  const user = useSelector((state) => state.user);
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        dispatch(showLoader());
        let _orders = await storeService.listOrders(user.idPadre);
        if (!_orders) {
          throw new Error(
            'No se ha podido recuperar la información de las órdenes'
          );
        }
        orderObjectsArray(_orders, 'idSolicitud', 'desc');
        setOrders(_orders);
      } catch (e) {
        showToast(
          'Error',
          'No se ha podido recuperar la información de las órdenes',
          TYPE_ICON.ERROR
        );
      } finally {
        dispatch(hideLoader());
      }
    };
    fetchOrders();
  }, [user, dispatch]);
  return (
    <React.Fragment>
      <TitleModule title="Estatus de Pedidos" />
      <Container className="mt-3">
        <OnlineContent>
          <div className="orders">
            <div className="orders-container">
              {orders.map((order) => (
                <OrderConfirmed order={order} key={order.idSolicitud} />
              ))}
            </div>
          </div>
        </OnlineContent>
      </Container>
    </React.Fragment>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import reducers from '../reducers/reducers';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import Config from './../config/general-config';

const persistConfigSessionStorage = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['user', 'menu', 'student', 'institution'],
};

const persistedReducer = persistReducer(persistConfigSessionStorage, reducers);

const rootStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: !Config.production,
});

let persistor = persistStore(rootStore);

export { rootStore, persistor };

import Dexie from 'dexie';
import { applyEncryptionMiddleware } from 'dexie-encrypted';
import { md5 } from '../utils/md5.util';
import {
  IDBEncryptedStoresUser,
  IDBStoresUser,
  IDBStoresData,
  IDBStoresData_v2,
  IDBEncryptedStoresData,
} from './IDBStores';

function connectToIDB(email, secretKey, isData = false) {
  const database = email + (isData ? '-data' : '');
  const encoder = new TextEncoder();
  const hash = md5(secretKey);
  const symmetricKey = encoder.encode(hash);

  const db = new Dexie(database);
  let store = IDBStoresUser;
  let storev2 = store;
  let encryptedStore = IDBEncryptedStoresUser;
  if (isData) {
    store = IDBStoresData;
    storev2 = IDBStoresData_v2;
    encryptedStore = IDBEncryptedStoresData;
  }
  applyEncryptionMiddleware(db, symmetricKey, encryptedStore);
  db.version(1).stores(store);
  db.version(2)
    .stores(storev2)
    .upgrade((tx) => {
      return tx
        .table('path')
        .toCollection()
        .modify((pathRecord) => {
          delete pathRecord.materias;
        });
    });
  return db;
}

function userExists(email) {
  const database = email;
  return Dexie.exists(database);
}

async function deleteDB() {
  const databases = await Dexie.getDatabaseNames();
  return databases.map((db) => {
    return Dexie.delete(db);
  });
}

export { connectToIDB as default, userExists, deleteDB };

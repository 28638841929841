import React, { useState, useEffect } from 'react';
import ProcedureStatusItem from './ProcedureStatusItem';
import { useSelector } from 'react-redux';
import ProceduresService from '../services/procedures.service';

export default function ProceduresStatus() {
  const [procedures, setProcedures] = useState([]);
  const { idPadre: id } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchProceduresOnProgress = async () => {
      try {
        const _procedures = await ProceduresService.getProceduresOnProgress(id);
        if (!_procedures) {
          throw new Error();
        }
        setProcedures(_procedures);
      } catch (e) {}
    };
    fetchProceduresOnProgress();
  }, [id]);

  return (
    procedures.length > 0 && (
      <div className="procedures-status my-4">
        <div className="procedure-status-title font-bold monserrat-font text-primary">
          Mis solicitudes
        </div>
        <div className="procedure-status-table mt-3">
          <ProcedureStatusItem isHeader={true} />
          {procedures.map((item) => (
            <ProcedureStatusItem item={item} key={item.idSolicitud} />
          ))}
        </div>
      </div>
    )
  );
}

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function PrivacyNotice({ showModal, onClose }) {
  return (
    <div className="privacy-notice">
      <Modal show={showModal} onHide={onClose} centered>
        <Modal.Header closeButton className="bg-orange text-white">
          <Modal.Title>Aviso de privacidad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Desarrollo Integrales en Tecnología de Guanajuato en la ciudad de
            Irapuato, Guanajuato, Registro Federal de Causantes: DIT1801111SR9,
            es responsable al recabar sus datos personales del uso que se le dé
            a los mismos y de su protección de acuerdo a lo establecido en este
            Aviso de Privacidad, en observancia a lo dispuesto por la Ley
            Federal de Protección de Datos Personales en Posesión de
            Particulares. Lo antes mencionado será bajo el nombre de nuestra
            herramienta tecnológica WEB y Móvil, Smart Academic Control. Nuestra
            empresa ofrece servicio tecnológico a instituciones educativas con
            las cuales se firma un contrato de servicios y protección de datos.
          </p>
          <p>
            Sabedores de la importancia que reviste el cumplimiento de las
            disposiciones legales, Desarrollo Integrales en Tecnología de
            Guanajuato, expresamos nuestro interés para que los datos personales
            que recabamos y manejamos, queden resguardados al margen de
            cualquier uso indebido de los mismos, por lo que nos
            responsabilizamos para lograr optimizar su información sin otro fin
            que el de servir fielmente a quienes nos confían sus datos
            personales, sean de persona física o moral.
          </p>
          <p className="font-bold">FINALIDAD Y USO DE SUS DATOS PERSONALES:</p>
          <ul>
            <li>A. FINES DE IDENTIFICACIÓN </li>
            <li>B. CONOCER ESTADO DE SALUD O CONDICIONES ESPECIALES</li>
            <li>C. INVITACIÓN A EVENTOS</li> <li>D. PAGO DE SERVICIOS</li>{' '}
            <li>E. TRÁMITES ACADÉMICOS</li>
            <li>F. REPOSTES ACADÉMICOS G. SERVICIOS EN LINEA</li>
          </ul>
          <p>
            Opciones y Medios que el responsable ofrece a los titulares para
            imitar el uso o divulgación de los datos: Sus datos NO se
            trasladarán a terceras personas o empresas.{' '}
          </p>
          <p className="font-bold">
            OBTENCIÓN Y ORIGEN DE SUS DATOS PERSONALES:
          </p>
          <p>
            Para las finalidades señaladas en el presente Aviso de Privacidad,
            podemos recabar sus datos personales de distintas formas: cuando
            usted nos los proporciona directamente; cuando visita nuestro sitio
            de Internet, aplicación móvil y cuando obtenemos información a
            través de otras fuentes que están permitidas por la ley.
          </p>
          <p className="font-bold">
            DATOS PERSONALES QUE RECABAMOS DE FORMA DIRECTA O A TRAVÉS DE
            DOCUMENTOS:
          </p>
          <p>
            Recabamos sus datos personales de forma directa cuando los padres de
            familia, representantes, proveedores, empleados o alumnos nos los
            proporciona por diversos medios, con el objeto de que le prestemos
            un servicio. Los datos que obtenemos por este medio pueden ser, de
            manera enunciativa mas no limitativa, entre otros:
          </p>
          <ul>
            <li>[Dato 1] Nombre Completo</li>
            <li>[Dato 2] Estado Civil</li>
            <li>[Dato 3] Edad</li>
            <li>[Dato 4] Sexo</li>
            <li>[Dato 5] Teléfono fijo y/o celular</li>
            <li>[Dato 6] Correo Electrónico</li>
            <li>[Dato 7] Dirección</li>
            <li>[Dato 8] Nivel de Escolaridad</li>
            <li>[Dato 9] RFC y/o CURP</li>
            <li>
              [Dato 10] Enfermedades, condiciones o necesidades especiales
            </li>
            <li>
              [Dato 11] En el caso de los docentes y empleados, los documentos
              de contratación como adicional a los puntos anteriores.
            </li>
          </ul>
          <p className="font-bold">DATOS PERSONALES SENSIBLES:</p>
          <p>
            Por la actividad del servicio que esta empresa realiza, tiene manejo
            de datos sensibles como los son creencias religiosas y padecimientos
            de salud.
          </p>
          <p>
            <span className="text-underline">
              VIDEOS E IMÁGENES FOTOGRÁFICAS. -
            </span>
            Dichas imágenes no se trasladarán a terceros por ningún concepto,
            salvo el caso de que sean requeridas por autoridad competente.
          </p>
          <p>
            <span className="text-underline">
              RESPONSABLE DE LA CUSTODIA DE LOS DATOS PERSONALES. -{' '}
            </span>
            Desarrollo Integrales en Tecnología en Guanajuato será el
            responsable del resguardo, esta información contará con protocolos
            estrictos del resguardo de la información a través de sus
            proveedores certificados y con las medidas de seguridad de estándar
            internacional en almacenamiento.
          </p>
          <p className="font-bold">
            ¿Cómo acceder o rectificar sus datos personales o cancelar u
            oponerse a su uso?
          </p>
          <p>
            Usted tiene derecho de acceder a sus datos personales que poseemos y
            a los detalles del tratamiento de los mismos, así como a
            rectificarlos en caso de ser inexactos o incompletos; cancelarlos
            cuando considere que no se requieren para alguna de las finalidades
            señaladas en el presente aviso de privacidad, estén siendo
            utilizados para finalidades no consentidas o haya finalizado la
            relación de servicio educativo, o bien, oponerse al tratamiento de
            los mismos para fines específicos.
          </p>
          <p>
            Los mecanismos que se han implementado para el ejercicio de dichos
            derechos son a través de la herramienta en internet o en la
            aplicación móvil, si el dato que requieren modificar no está dentro
            de las herramientas tecnológicas de Smart Academic Control, tendrá
            que ingresar un oficio a la institución donde se encuentra inscrito.
            Para más información, puede ingresar a{' '}
            <a href="http://www.smartacademic.mx">www.smartacademic.mx</a> o
            enviar un correo a{' '}
            <a href="mailto:informes@smartacademic.mx">
              informes@smartacademic.mx
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={onClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import baseService from './base.service';
import Config from '../config/general-config';
import wsResponseHandler from '../handlers/wsResponse.hanlder';
import { getToken } from '../helpers/localstorage.helper';

const educationalPathService = {
  getEducationalPath: async (idAlumno) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.EDUCATIONAL_PATH,
      {
        _idAlumno: idAlumno,
      },
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
  getEvaluations: async (params) => {
    const response = await baseService.getRequest(
      Config.ENDPOINTS.EVALUATIONS,
      params,
      {
        Authorization: `Bearer ${getToken()}`,
      }
    );
    return wsResponseHandler(response);
  },
};

export default educationalPathService;

import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleOpen } from '../../stores/slices/menuSlice';

export default function HamburguerMenu() {
  const dispatch = useDispatch();
  return (
    <i
      className="fa fa-2x fa-bars cursor-pointer"
      onClick={() => {
        dispatch(toggleOpen());
      }}
    ></i>
  );
}

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TitleModule from '../core/TitleModule';
import SchoolarshipsOptions from '../SchoolarshipsOptions';
import coverBecas from './../../assets/imgs/cover_becas.png';
import './../../styles/components/Schoolarships.scss';
import useSchoolarShips from './../../hooks/useSchoolarships';
import NoContent from '../generic/NoContent';

export default function Schoolarships() {
  const schoolarships = useSchoolarShips([]);
  return (
    <React.Fragment>
      <TitleModule title="Becas" />
      <div className="becas-img-cover-container">
        <img className="becas-img-cover" src={coverBecas} alt="Becas" />
      </div>
      <Container className="schoolarships pt-4 px-md-0">
        <Row>
          <Col xs={12}>
            <div className="schoolarship-text verdana-font mb-4">
              En este apartado se proporciona acceso a documentos relacionados a
              Becas, tales como: la <b>Convocatoria</b> para el proceso de
              otorgamiento de beca, los <b>tipos de beca</b> existentes en la
              Institución Educativa, información sobre el <b>trámite de beca</b>{' '}
              y los <b>resultados de becas otorgadas.</b>
            </div>
          </Col>
          <Col xs={12} className="mb-4">
            <NoContent
              title="La institución aún no carga información relacionada a becas"
              thereAreContent={schoolarships}
            >
              <SchoolarshipsOptions schoolarships={schoolarships} />
            </NoContent>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

import React, { useRef, useState } from 'react';
import './../styles/components/UploadDocument.scss';
import eyeIcon from '../assets/imgs/eye.svg';
import uploadCloudIcon from '../assets/imgs/upload.svg';
import noFileIcon from '../assets/imgs/circle_x.svg';
import checkFileIcon from '../assets/imgs/circle_check.svg';
import validatingIcon from '../assets/imgs/validandoArchivo.svg';
import Config from './../config/general-config';
import CustomTooltip from './generic/CustomTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import digitalFileService from './../services/digitalFile.service';
import {
  confirmAction,
  DURATION_TOAST,
  showToast,
  TYPE_ICON,
} from '../helpers/sweetAlert.helper';
import {
  getExtentionFromMimeType,
  calculateSizeOfFile,
  fileToB64,
  getOnlyBytesFromb64String,
} from '../utils/utils';
import SyncController from './../controllers/sync.controller';
import DigitalFileController from './../controllers/digitalFile.controller';

const getDataFileStatus = (status) => {
  status = parseInt(status, 10);
  const statusIcons = {
    [Config.FILE_STATUS.NO_LOADED]: {
      icon: noFileIcon,
      label: 'Documento no cargado',
    },
    [Config.FILE_STATUS.LOADED]: {
      icon: validatingIcon,
      label: 'Validando documento',
    },
    [Config.FILE_STATUS.VALIDATED]: {
      icon: checkFileIcon,
      label: 'Documento validado',
    },
    [Config.FILE_STATUS.REJECTED]: {
      icon: noFileIcon,
      label: 'Documento rechazdo',
    },
  };
  return statusIcons[status] ?? statusIcons[Config.FILE_STATUS.NO_LOADED];
};

export default function UploadDocument({
  document,
  preview,
  allowModifications,
  activeStudent,
}) {
  const inputRef = useRef(null);
  const {
    idDocumento,
    documento,
    archivo: { estatus, idArchivo },
  } = document;
  const dispatch = useDispatch();
  const [statusFile, setStatusFile] = useState(getDataFileStatus(estatus));
  const [idFile, setIdFile] = useState(idArchivo);
  const { isOnline } = useSelector((state) => state.internet);
  const user = useSelector((state) => state.user);
  const digitalFileController = useRef(
    new DigitalFileController(user.email, user.keyDecrypt)
  );

  const handleUpload = () => {
    if (!allowModifications) {
      showToast(
        'Modificaciones deshabilitadas',
        'La institución ha deshabilitado la carga de documentos',
        TYPE_ICON.INFO
      );
      resetFileInput();
      return;
    }
    inputRef.current?.click();
  };
  const resetFileInput = () => {
    inputRef.current.value = null;
  };

  const uploadDocument = async (event) => {
    const docFile = inputRef.current.files[0];
    if (!docFile) {
      return;
    }
    const confirm = await confirmAction(
      'Subir documento',
      `El documento <i><strong>${docFile.name}</strong></i> será cargado. ¿Desea continuar?`
    );
    if (!confirm) {
      return;
    }
    if (
      !Config.validDocumentsExtentions.includes(
        getExtentionFromMimeType(docFile.type)
      )
    ) {
      showToast(
        'Archivo inválido',
        'El archivo seleccionado es inválido',
        TYPE_ICON.WARNING
      );
      resetFileInput();
      return;
    }
    if (calculateSizeOfFile(docFile.size, 'MB') > Config.MAX_DOC_SIZE_MB) {
      showToast(
        'Archivo inválido',
        `El archivo seleccionado tiene un peso mayor a ${Config.MAX_DOC_SIZE_MB} Mb`,
        TYPE_ICON.WARNING
      );
      resetFileInput();
      return;
    }

    try {
      dispatch(showLoader());
      const formData = new FormData();
      // INFO: Name of data doesn't matter
      formData.append('doc', docFile);
      if (isOnline) {
        const response = await digitalFileService.uploadDocument(
          activeStudent.id,
          document.idDocumento,
          formData
        );
        if (!response) throw Error('No se ha podido cargar el documento');
        setIdFile(response.idArchivo);
        showToast(
          'Éxito',
          'El documento ha sido cargado exitósamente',
          TYPE_ICON.SUCCESS
        );
      } else {
        const syncController = new SyncController(user.email, user.keyDecrypt);
        await syncController.putFileAction({
          idAlumno: activeStudent.id,
          idUsuario: user.id,
          archivo: docFile,
          tipo: Config.SYNC_ACTIONS.UPLOAD_DOC,
          idDocumento: null,
          mimeType: docFile.type,
          idNotificacion: null,
          extraData: null,
        });
        setIdFile('offline');
        await digitalFileController.current.updateDataFile(
          idDocumento,
          activeStudent.id
        );
        showToast(
          'Procesado sin conexión',
          'El archivo ha sido actualizado de manera local, cuando el dispositivo cuente con conexión a internet, los cambios serán sincronizados al servidor',
          TYPE_ICON.INFO
        );
      }
      // INFO: Update file en indexed DB
      const b64File = await fileToB64(docFile);
      const _file = {
        fileData: getOnlyBytesFromb64String(b64File),
        mimeType: docFile.type,
        name: documento,
      };
      await digitalFileController.current.updateFile({
        file: _file,
        idDocumento,
        idAlumno: activeStudent.id,
      });
      setStatusFile(getDataFileStatus(Config.FILE_STATUS.LOADED));
    } catch (e) {
      showToast(
        'Error',
        'No se ha podido cargar el documento. Intente nuevamente por favor.',
        TYPE_ICON.ERROR
      );
    } finally {
      resetFileInput();
      dispatch(hideLoader());
    }
  };

  const viewDocument = async () => {
    try {
      dispatch(showLoader());

      let _file = null;
      if (isOnline) {
        const response = await digitalFileService.viewDocument(idFile);
        if (!response) {
          showToast(
            'Documento no encontrado',
            'No se ha encontrado el documento en el servidor o no ha sido cargado',
            TYPE_ICON.WARNING
          );
          return;
        }
        _file = {
          fileData: response.archivoBytes,
          mimeType: response.tipoArchivo,
          name: documento,
        };
        await digitalFileController.current.updateFile({
          file: _file,
          idDocumento: document.idDocumento,
          idAlumno: activeStudent.id,
        });
      } else {
        _file = await digitalFileController.current.readFile(
          document.idDocumento,
          activeStudent.id
        );
        if (!_file) {
          showToast(
            'Documento no almacenado localmente',
            'No se cuenta con una copia local del archivo para visualizarse sin conexión. Las copias locales se almacenan cuando el archivo se visualiza al menos una vez con conexión a internet activa',
            TYPE_ICON.INFO,
            DURATION_TOAST.INFINITE
          );
          return;
        }
      }
      if (_file) {
        preview(_file);
      }
    } catch (e) {
      showToast(
        'Error visualizando documento',
        'No se ha podido visualizar el documento. Recargue la página e intente nuevamente por favor',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div className="upload-document mb-3">
      <div className="upload-document-container">
        <div className="document-name">
          <span className="text-primary font-bold f-12px">{documento}</span>
        </div>
        <div className="document-options">
          {idFile && (
            <img
              src={eyeIcon}
              alt="Ver documento"
              className="cursor-pointer document-option"
              onClick={viewDocument}
            />
          )}
          <img
            src={uploadCloudIcon}
            alt="Subir documento"
            className="cursor-pointer document-option"
            onClick={handleUpload}
          />
          <input
            type="file"
            name="document"
            id={`document_${idDocumento}`}
            hidden
            ref={inputRef}
            accept={Config.validDocumentsExtentions}
            onChange={uploadDocument}
          />
          <CustomTooltip text={statusFile.label}>
            <img
              src={statusFile.icon}
              alt={statusFile.label}
              className="document-option"
            />
          </CustomTooltip>
        </div>
      </div>
    </div>
  );
}

import React, { useRef } from 'react';
import './../styles/components/NotificationsNotReaded.scss';
import { useLiveQuery } from 'dexie-react-hooks';
import NotificationController from '../controllers/notification.controller';
import { useSelector } from 'react-redux';

export default function NotificationsNotReaded({ type, style }) {
  const user = useSelector((state) => state.user);
  const studentId = useSelector((state) => state.student.id);
  const notificationController = useRef(
    new NotificationController(user.email, user.keyDecrypt)
  );

  const itemsNotReaded = useLiveQuery(
    () => notificationController.current.notReaded(user.id, studentId, type),
    [studentId]
  );

  return (
    itemsNotReaded > 0 && (
      <div className="notifications-not-readed" style={style}>
        <div className="notifications-not-readed-container">
          <span className="counter-not-readed">{itemsNotReaded}</span>
        </div>
      </div>
    )
  );
}

import Student from '../models/student.model';

class StudentController {
  #model;

  constructor(email, secretKey) {
    this.#model = new Student(email, secretKey);
  }

  addStudent(student) {
    return this.#model.addStudent(student);
  }

  putStudent(student) {
    return this.#model.putStudent(student);
  }

  bulkPutStudents(students) {
    return this.#model.bulkPutStudents(students);
  }

  readStudent(id) {
    return this.#model.readStudent(id);
  }

  readStudents(idTutor) {
    return this.#model.readStudents(idTutor);
  }

  updatePhotoStudent(id, photo) {
    return this.#model.updatePhoto(id, photo);
  }

  updateSizeWeight(id, size, weight) {
    return this.#model.updateSizeWeight(id, size, weight);
  }

  async readSizeWeight(id) {
    const { peso, estatura } = await this.readStudent(id);
    return { weight: peso, size: estatura };
  }
}

export default StudentController;

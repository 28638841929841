import React from 'react';
import { Row, Col } from 'react-bootstrap';
import StudentMenu from '../StudentMenu';
import Calendar from '../Calendar';
import Notifications from '../Notifications';
import './../../styles/components/HeaderMobile.scss';
import HamburguerMenu from '../generic/HamburguerMenu';
import { useSelector } from 'react-redux';

export default function HeaderMobile() {
  const activeMenu = useSelector((state) => state.menu.active.label);
  return (
    <Row className="d-flex header-mobile">
      <Col
        xs={12}
        className="header-mobile-title-container d-flex align-items-center"
      >
        <div className="header-title-menu">
          <HamburguerMenu />
        </div>
        <span className="header-mobile-title monserrat-bold-font f-18px">
          {activeMenu}
        </span>
      </Col>
      <Col xs={12} className="header-mobile-options-container">
        <Row>
          <Col xs={4} className="header-mobile-option">
            <StudentMenu />
          </Col>
          <Col xs={4} className="header-mobile-option">
            <Calendar />
          </Col>
          <Col xs={4} className="header-mobile-option">
            <Notifications />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

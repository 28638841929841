import React from 'react';
import CustomTooltip from './CustomTooltip';
import useConfig from './../../hooks/useConfig';

export default function LockModifications() {
  const { banderaCambios: allowModifications } = useConfig();

  return (
    <div className="lock-modifications">
      <div className="lock-modifications-container d-flex justify-content-end mb-4">
        {!allowModifications ? (
          <CustomTooltip
            className="cursor-pointer"
            text="Actualmente no es posible actualizar información ya que la institución ha limitado esta funcionalidad"
          >
            <i className="fa fa-lock text-primary f-25px"></i>
          </CustomTooltip>
        ) : (
          <CustomTooltip
            className="cursor-pointer"
            text="Es posible actualizar información"
          >
            <i className="fa fa-lock-open text-orange f-25px"></i>
          </CustomTooltip>
        )}
      </div>
    </div>
  );
}

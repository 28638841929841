import React from 'react';

import {
  confirmAction,
  showMessage,
  showToast,
  TYPE_ICON,
} from '../helpers/sweetAlert.helper';
import storeService from './../services/store.service';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import { useNavigate } from 'react-router-dom';
import { cleanCart } from '../stores/slices/carSlice';

export default function PayOrder({ order }) {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const payOrder = async () => {
    const accept = await confirmAction(
      'Generar pedido',
      'El pedido será generado con los productos que se encuentran en su carrito de compras ¿Desea continuar?'
    );
    try {
      if (accept) {
        dispatch(showLoader());
        const finalOrder = order.map((product) => {
          const { idArticulo: idProducto, cantidad } = product;
          return {
            idProducto,
            cantidad,
          };
        });
        const response = await storeService.generateOrder(
          user.idPadre,
          finalOrder
        );
        if (!response) {
          return;
        }
        dispatch(cleanCart());
        const { idPedido } = response;
        await showMessage(
          `Pedido ${idPedido} registrado exitósamente`,
          `<p>Se ha creado su pedido con el folio <strong style="font-size: 20px;">${idPedido}</strong> de manera exitosa. Una vez que el pedido sea validado por parte de la institución, usted será notificado<p>`
        );
        navigate('/pedidos');
      }
    } catch (e) {
      showToast(
        'Error',
        'No se ha podido completar el pedido. Intente nuevamente por favor',
        TYPE_ICON.ERROR
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div className="payorder">
      <button
        type="button"
        className="btn btn-sm bg-orange text-white text-uppercase font-bold btn-pay"
        onClick={payOrder}
      >
        solicitar
      </button>
    </div>
  );
}

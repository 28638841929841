import { showToast, TYPE_ICON } from './sweetAlert.helper';
import { showMessage } from './sweetAlert.helper';
import Config from './../config/general-config';
import notificacionesService from './../services/notificaciones.service';
// import { getActiveUser } from './localstorage.helper';

const showNotification = (message, options = {}) => {
  new Notification(message, options);
};

function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

const enableNotifications = async (idUser) => {
  try {
    await enablePushNotifications(idUser);
    if (!('Notification' in window) && !iOS()) {
      showToast(
        'Funcionalidad no soportada',
        'Este navegador no soporta notificaciones',
        TYPE_ICON.INFO
      );
      return;
    }

    if (Notification.permission === 'granted') {
      return;
    }
    if (Notification.permission === 'denied') {
      return;
    }
    await showMessage(
      'Activar notificaciones',
      'Si desea recibir notificaciones push en su dispositivo, por favor actívelas cuando aparezca el diálogo correspondiente en su navegador'
    );

    Notification.requestPermission().then(async function (permission) {
      if (permission === 'granted') {
        await enablePushNotifications(idUser);
        showNotification('¡Las notificaciones se han activado correctamente!');
      }
    });
  } catch (e) {
    console.log('ERROR Enabling notifications', e);
    showToast(
      'Error',
      'No se ha podido suscribir a las notificaciones push ' + e.message,
      TYPE_ICON.WARNING
    );
  }
};

const enablePushNotifications = async (idUser) => {
  let swr = null;
  let subs = null;
  try {
    // if (idUser === getActiveUser()) {
    //   return;
    // }
    swr = await navigator.serviceWorker.ready;
    subs = await swr.pushManager.getSubscription();

    if (subs) {
      await subs.unsubscribe();
    }
    subs = await swr.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: Config.PUBLIC_VAPID_KEY,
    });
    const response = await notificacionesService.subscribe(idUser, subs);
    if (!response) {
      throw new Error('No se ha podido suscribir a las notificaciones push');
    }
  } catch (e) {
    console.log(e);
    subs && (await subs.unsubscribe());
    showToast(
      'Error',
      'No se ha podido suscribir a las notificaciones push',
      TYPE_ICON.WARNING
    );
  }
};

const listenBroadcastNotification = (callback = () => {}) => {
  const broadcast = new BroadcastChannel('sac_notifications');
  broadcast.onmessage = (event) => {
    if (event.data && event.data.type === 'SAC_NEW_NOTIFICATION') {
      const { payload } = event.data;
      callback();
    }
  };
};

export { showNotification, enableNotifications, listenBroadcastNotification };

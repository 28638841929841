import DigitalFileModel from './../models/digitalFile.model';

class DigitalFileController {
  #model;

  constructor(email, secretKey) {
    this.#model = new DigitalFileModel(email, secretKey);
  }

  putDigitalFile(digitalFile, idStudent) {
    digitalFile = digitalFile.map((item) => ({
      idAlumno: idStudent,
      ...item,
    }));
    return this.#model.putDigitalFile(digitalFile, idStudent);
  }

  updateFile(data) {
    return this.#model.updateFile(data);
  }

  readFile(idDocumento, idStudent) {
    return this.#model.readFile(idDocumento, idStudent);
  }

  readDigitalFile(idStudent) {
    return this.#model.readDigitalFile(idStudent);
  }

  updateDataFile(idDocumento, idStudent) {
    return this.#model.updateDataFile(idDocumento, idStudent);
  }
}

export default DigitalFileController;

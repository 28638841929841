import React, { useState } from 'react';
import FileViewer from './generic/FileViewer';
import { useDispatch } from 'react-redux';
import fileService from '../services/file.service';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';

export default function ResultProcedure({ documents = [] }) {
  const [attachedFile, setAttachedFile] = useState(null);
  const dispatch = useDispatch();
  const handleAttachmentClick = async (idAttachment) => {
    try {
      dispatch(showLoader());
      let _attachedFile = await fileService.getDocument(idAttachment);
      if (!_attachedFile) {
        throw new Error('No se ha podido cargar el archivo');
      }
      _attachedFile = {
        name: idAttachment,
        fileData: _attachedFile.archivoBytes,
        mimeType: _attachedFile.tipoArchivo,
      };
      setAttachedFile(_attachedFile);
    } catch (e) {
      showToast('Error', 'No se ha podido cargar el archivo', TYPE_ICON.ERROR);
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <React.Fragment>
      <FileViewer file={attachedFile}></FileViewer>
      <div className="result-procedure p-2">
        <p className="text-primary font-bold">Resultado del trámite</p>
        <div className="d-flex">
          {documents.map((doc) => (
            <span
              className="badge bg-orange cursor-pointer text-white"
              onClick={() => {
                handleAttachmentClick(doc.idArchivoCargado);
              }}
              key={doc.idArchivoCargado}
            >
              <i className="fas fa-file-download">&nbsp;</i>
              {doc.nombreDocumento}
            </span>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

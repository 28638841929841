import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';

export default function SVGIconFromText({ tagText }) {
  const textSanitized = useMemo(() => {
    if (!tagText) return '';
    return DOMPurify.sanitize(tagText.replace(/clip-path=".*"/g, ''));
  }, [tagText]);

  return (
    <div
      className="svg-icon-from-text"
      dangerouslySetInnerHTML={{ __html: textSanitized }}
    />
  );
}

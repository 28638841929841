import React from 'react';
import { useSelector } from 'react-redux';
import './../../styles/components/OnlineContent.scss';

export default function OnlineContent({ children }) {
  const isOnline = useSelector((state) => state.internet.isOnline);
  return isOnline ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <div className="online-content d-flex justify-content-center align-items-center flex-column">
      <i className="fa fa-wifi no-connection-icon"></i>
      <span className="no-connection-text">
        Este funcionalidad solo está disponible online
      </span>
    </div>
  );
}

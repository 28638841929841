import baseService from './base.service';
import Config from '../config/general-config';
import { getToken } from '../helpers/localstorage.helper';

const studentService = {
  updateSizeWeight: async (idAlumno, size, weight) => {
    const response = await baseService.postRequest(
      `${Config.ENDPOINTS.UPDATE_SIZE_WEIGHT}?_idAlumno=${idAlumno}&_peso=${weight}&_altura=${size}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.estatus;
  },
};

export default studentService;

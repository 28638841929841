import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './../styles/components/BoardCalendar.scss';

export default function BoardCalendar() {
  return (
    <div className="board-calendar mx-auto">
      <Calendar />
    </div>
  );
}

import React from 'react';
import { Col } from 'react-bootstrap';

import disabled_1 from '../assets/imgs/1_disabled.svg';
import disabled_2 from '../assets/imgs/2_disabled.svg';
import disabled_3 from '../assets/imgs/3_disabled.svg';
import disabled_4 from '../assets/imgs/4_disabled.svg';
import disabled_5 from '../assets/imgs/5_disabled.svg';
import disabled_6 from '../assets/imgs/6_disabled.svg';
import progress_1 from '../assets/imgs/1_progress.svg';
import progress_2 from '../assets/imgs/2_progress.svg';
import progress_3 from '../assets/imgs/3_progress.svg';
import progress_4 from '../assets/imgs/4_progress.svg';
import progress_5 from '../assets/imgs/5_progress.svg';
import progress_6 from '../assets/imgs/6_progress.svg';
import finished_1 from '../assets/imgs/1_finished.svg';
import finished_2 from '../assets/imgs/2_finished.svg';
import finished_3 from '../assets/imgs/3_finished.svg';
import finished_4 from '../assets/imgs/4_finished.svg';
import finished_5 from '../assets/imgs/5_finished.svg';
import finished_6 from '../assets/imgs/6_finished.svg';

//University
import disabled_sup_1 from '../assets/imgs/1_sup_disabled.svg';
import disabled_sup_2 from '../assets/imgs/2_sup_disabled.svg';
import disabled_sup_3 from '../assets/imgs/3_sup_disabled.svg';
import disabled_sup_4 from '../assets/imgs/4_sup_disabled.svg';
import disabled_sup_5 from '../assets/imgs/5_sup_disabled.svg';
import disabled_sup_6 from '../assets/imgs/6_sup_disabled.svg';
import progress_sup_1 from '../assets/imgs/1_sup_progress.svg';
import progress_sup_2 from '../assets/imgs/2_sup_progress.svg';
import progress_sup_3 from '../assets/imgs/3_sup_progress.svg';
import progress_sup_4 from '../assets/imgs/4_sup_progress.svg';
import progress_sup_5 from '../assets/imgs/5_sup_progress.svg';
import progress_sup_6 from '../assets/imgs/6_sup_progress.svg';
import finished_sup_1 from '../assets/imgs/1_sup_finished.svg';
import finished_sup_2 from '../assets/imgs/2_sup_finished.svg';
import finished_sup_3 from '../assets/imgs/3_sup_finished.svg';
import finished_sup_4 from '../assets/imgs/4_sup_finished.svg';
import finished_sup_5 from '../assets/imgs/5_sup_finished.svg';
import finished_sup_6 from '../assets/imgs/6_sup_finished.svg';

const LEVELS = {
  PREESCOLAR: 1,
  PRIMARIA: 2,
  SECUNDARIA: 3,
  PREPARATORIA: 4,
};

const getImgFromGrade = (item, grade, level) => {
  let disabled = [],
    progress = [],
    finished = [];
  if (level < LEVELS.PREPARATORIA) {
    disabled = [
      disabled_1,
      disabled_2,
      disabled_3,
      disabled_4,
      disabled_5,
      disabled_6,
    ];
    progress = [
      progress_1,
      progress_2,
      progress_3,
      progress_4,
      progress_5,
      progress_6,
    ];
    finished = [
      finished_1,
      finished_2,
      finished_3,
      finished_4,
      finished_5,
      finished_6,
    ];
  } else {
    disabled = [
      disabled_sup_1,
      disabled_sup_2,
      disabled_sup_3,
      disabled_sup_4,
      disabled_sup_5,
      disabled_sup_6,
    ];
    progress = [
      progress_sup_1,
      progress_sup_2,
      progress_sup_3,
      progress_sup_4,
      progress_sup_5,
      progress_sup_6,
    ];
    finished = [
      finished_sup_1,
      finished_sup_2,
      finished_sup_3,
      finished_sup_4,
      finished_sup_5,
      finished_sup_6,
    ];
  }

  if (!item) {
    return disabled[grade];
  }
  grade -= 1; // INFO: To match  grade number with array index

  if (item.estatusAlumnoGrupo) {
    return progress[grade];
  }
  return finished[grade];
};

export default function StatusGradeEducationalPath({
  item,
  index,
  activeGrade,
  handleClick,
  level,
}) {
  return item ? (
    <Col>
      <div
        className={`img-grade-container ${
          item.idGrado === activeGrade?.idGrado ? 'active' : ''
        }`}
      >
        <img
          className="cursor-pointer"
          src={getImgFromGrade(item, item.idGrado, level)}
          alt="Estatus ciclo escolar"
          onClick={() => {
            handleClick(item);
          }}
        />
      </div>
    </Col>
  ) : (
    <Col>
      <div className="img-grade-container">
        <img
          className=""
          src={getImgFromGrade(item, index, level)}
          alt="Estatus ciclo escolar"
        />
      </div>
    </Col>
  );
}

import React from 'react';
import eyeIcon from '../assets/imgs/eye.svg';
import { useNavigate } from 'react-router-dom';

export default function ProcedureStatusActions({ item }) {
  const { idSolicitud: id} = item;
  const navigate = useNavigate();
  const handleGoToDetail = () => {
    navigate(`/solicitar-tramite/${id}`);
  };

  return (
    <div className="procedure-status-actions d-flex justify-content-around">
      <img
        src={eyeIcon}
        alt="Ver detalle"
        className="cursor-pointer"
        onClick={handleGoToDetail}
      />
    </div>
  );
}

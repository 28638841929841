import React from 'react';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import { Container } from 'react-bootstrap';
import useWindowDimensions from './../../hooks/useWindowDimensions';
import Config from './../../config/general-config';

export default function Header() {
  const { width } = useWindowDimensions();

  return (
    <header>
      <Container fluid className="h-100">
        {width < Config.MOBILE_WIDTH ? <HeaderMobile /> : <HeaderDesktop />}
      </Container>
    </header>
  );
}

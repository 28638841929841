import React from 'react';
import RouterApp from './../../routers/RouterApp';
import Footer from './Footer';
import Loader from './Loader';
import NewVersion from './NewVersion';
import useInternet from '../../hooks/useInternet';

export default function Layout() {
  // eslint-disable-next-line no-unused-vars
  const internet = useInternet();
  return (
    <div id="page-container">
      <div id="content-wrap">
        <NewVersion />
        <Loader />
        <RouterApp />
      </div>
      <Footer />
    </div>
  );
}

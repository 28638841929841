import BaseModel from './base.model';

class Menu extends BaseModel {
  constructor(email, secretKey) {
    super(email, secretKey, true);
  }

  async updateMenu(menu) {
    return this.db.transaction('rw', [this.db.menu], async () => {
      await this.db.menu.clear();
      await this.db.menu.bulkAdd(menu);
    });
  }

  readMenu() {
    return this.db.menu.toArray();
  }
}

export default Menu;

import React, { useMemo } from 'react';
import './../styles/components/BoardMainLink.scss';
import SVGIconFromText from './generic/SVGIconFromText';
import { Link } from 'react-router-dom';
import NotificationsNotReaded from './NotificationsNotReaded';

export default function BoardMainLink({
  icon,
  title,
  href,
  backgroundColor,
  fontColor,
}) {
  const style = useMemo(
    () => ({
      backgroundColor: backgroundColor,
      color: fontColor,
    }),
    [backgroundColor, fontColor]
  );

  return (
    <div className="board-main-link cursor-pointer scale-hover">
      <Link
        className="board-main-link-container d-flex"
        to={href}
        style={style}
      >
        <div className="board-main-icon-container">
          <SVGIconFromText tagText={icon} />
        </div>
        <div className="board-main-title d-flex">
          <span className="arvo-font font-bold my-auto">{title}</span>
        </div>
      </Link>
      {title.toLowerCase().includes('tareas') && (
        <NotificationsNotReaded style={{ top: 10, right: 10 }} />
      )}
    </div>
  );
}

import React from 'react';
import '../../styles/components/NoContent.scss';
import { useSelector } from 'react-redux';

export default function NoContent({
  thereAreContent,
  children,
  title = 'No existe contenido para mostrar',
  icon = 'fa-box-open',
}) {
  const loadingData = useSelector((state) => state.loader.show);
  return thereAreContent || loadingData ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <div className="no-content d-flex justify-content-center align-items-center flex-column px-3">
      <i className={`fa ${icon} no-content-icon`}></i>
      <span className="no-content-text">{title}</span>
    </div>
  );
}

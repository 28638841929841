import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import useWindowDimensions from './../../hooks/useWindowDimensions';
import '../../styles/components/PDFViewer.scss';

const PADDING_PDF_SIDES = 16;

export default function PDFViewer({ file }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { width: widthWindow, height: heightWindow } = useWindowDimensions();
  const [width, setWidth] = useState(200);

  const handleLoadPDF = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  useEffect(() => {
    if (widthWindow > 1200 - PADDING_PDF_SIDES * 2) {
      setWidth(1200);
    } else {
      setWidth(widthWindow - PADDING_PDF_SIDES * 2);
    }
  }, [widthWindow]);

  const handleNextPage = () => {
    const nextPage = pageNumber + 1;
    if (nextPage > numPages) {
      return;
    }
    setPageNumber(nextPage);
  };

  const handlePrevPage = () => {
    const prevPage = pageNumber - 1;
    if (prevPage < 1) {
      return;
    }
    setPageNumber(prevPage);
  };

  const errorPDF = (e) => {
    console.log(e);
  };

  return (
    <div className="pdf-viewer" style={{ height: heightWindow }}>
      <Document
        file={`data:${file.mimeType};base64,${file.fileData}`}
        onLoadSuccess={handleLoadPDF}
        onLoadError={errorPDF}
        loading={
          <div className="d-block w-100">
            <i className="fas fa-fan fa-spin"></i>Cargando página, espere por
            favor...
          </div>
        }
        noData={'No se ha podido cargar el PDF'}
      >
        <Page pageNumber={pageNumber} width={width} />
      </Document>
      <div className="pdf-paginator">
        <div className="prev cursor-pointer" onClick={handlePrevPage}>
          <i className="fa-solid fa-chevron-left fa-2x"></i>
        </div>
        <div className="paginator text-center">
          <span>
            {pageNumber}/{numPages}
          </span>
        </div>
        <div className="next cursor-pointer" onClick={handleNextPage}>
          <i className="fa-solid fa-chevron-right fa-2x"></i>
        </div>
      </div>
    </div>
  );
}

import BaseModel from './base.model';

class Student extends BaseModel {
  constructor(email, secretKey) {
    super(email, secretKey, true);
  }

  addStudent(student) {
    return this.db.student.add(student);
  }

  putStudent(student) {
    return this.db.student.put(student);
  }

  bulkPutStudents(students) {
    return this.db.student.bulkPut(students);
  }

  readStudent(id) {
    return this.db.student.where('idAlumno').equals(id).first();
  }

  readStudents(idTutor) {
    return this.db.student.where('idTutor').equals(idTutor).toArray();
  }

  updatePhoto(id, photo) {
    return this.db.student
      .where('idAlumno')
      .equals(id)
      .modify({ fotoBase64: photo });
  }

  updateSizeWeight(id, size, weight) {
    return this.db.student.where('idAlumno').equals(id).modify({
      peso: weight,
      estatura: size,
    });
  }
}

export default Student;

import React from 'react';
import StoreProduct from './StoreProduct';

export default function StoreProducts({ products }) {
  return (
    <div className="store-products">
      <div className="store-products-container mx-auto">
        {products &&
          products.map((product) => (
            <StoreProduct product={product} key={product.idArticulo} />
          ))}
      </div>
    </div>
  );
}

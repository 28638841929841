import React, { useState, useEffect } from 'react';
import '../styles/components/Sync.scss';
import NotificationController from './../controllers/notification.controller';
import { useSelector } from 'react-redux';
import SyncController from './../controllers/sync.controller';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';

export default function Sync() {
  const [syncActive, setSyncActive] = useState(false);
  const user = useSelector((state) => state.user);
  const { isOnline } = useSelector((state) => state.internet);

  useEffect(() => {
    const deleteOldNotifications = async () => {
      try {
        const notificationController = new NotificationController(
          user.email,
          user.keyDecrypt
        );
        await notificationController.deleteOldNotifications();
      } catch (e) {}
    };
    deleteOldNotifications();
  }, [user.email, user.keyDecrypt]);

  useEffect(() => {
    if (!isOnline) {
      return;
    }
    try {
      const syncPendingActions = async () => {
        const syncController = new SyncController(user.email, user.keyDecrypt);
        setSyncActive(true);
        await syncController.syncActions();
      };
      syncPendingActions();
    } catch (e) {
      showToast('Sincronización pendiente', '', TYPE_ICON.WARNING);
    } finally {
      setSyncActive(false);
    }
  }, [isOnline, user.email, user.keyDecrypt]);

  return (
    syncActive && (
      <div className="sync">
        <div className="sync-container">
          <div className="sync-icon">
            <i className="fas fa-sync-alt fa-spin"></i>
          </div>
        </div>
      </div>
    )
  );
}

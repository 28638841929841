function getImgWidthHeight(img, width, height) {
  if ((width <= 0 && height <= 0) || (width === 'auto' && height === 'auto')) {
    return {
      width: img.width,
      height: img.height,
    };
  }

  if (width > 0 && height > 0) {
    return {
      width: img.width,
      height: img.height,
    };
  }

  if (width === 'auto') {
    return {
      height: Number(height),
      width: Number(height) / calculateAspectRatio(img),
    };
  }

  if (height === 'auto') {
    return {
      width: Number(width),
      height: Number(width) / calculateAspectRatio(img),
    };
  }

  return { width, height };
}

function calculateAspectRatio(img) {
  const aspetcRadio =
    img.width > img.height ? img.width / img.height : img.width / img.height;
  return isNaN(aspetcRadio) ? 1 : aspetcRadio;
}

function createImage(ev) {
  const imageContent = ev.target.result;
  const img = new Image();
  img.src = imageContent;
  return img;
}

export const ImagesCompressor = (file, options = null) => {
  const DEF_QUALITY_RADIO = 0.7;
  const DEFAULT_LONG = 'auto';
  const imageType = options?.mimeType || file.type || 'image/jpeg';
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = (imgReader) => {
      const img = createImage(imgReader);
      img.onload = () => {
        const width = options?.width
          ? options.width > img.width
            ? DEFAULT_LONG
            : options.width
          : img.width;
        const height = options?.height
          ? options.height > img.height
            ? DEFAULT_LONG
            : options.height
          : img.height;

        const imgDim = getImgWidthHeight(img, width, height);
        const elem = document.createElement('canvas');
        elem.width = imgDim.width;
        elem.height = imgDim.height;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, imgDim.width, imgDim.height);
        ctx.canvas.toBlob(
          (blob) => {
            const compressedImage = new File([blob], file.name, {
              type: imageType,
              lastModified: Date.now(),
            });
            resolve(file.size < compressedImage.size ? file : compressedImage);
          },
          imageType,
          options?.qualityRatio || DEF_QUALITY_RADIO
        );
      };
    };
    reader.onerror = (error) => reject(error);
  });
};

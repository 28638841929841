import React, { useCallback } from 'react';
import HamburguerMenu from '../generic/HamburguerMenu';
import './../../styles/components/SideMenu.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../stores/slices/userSlice';
import SideMenuItem from './SideMenuItem';
import SideMenuHeader from './SideMenuHeader';
import { confirmAction } from '../../helpers/sweetAlert.helper';
import useMenu from './../../hooks/useMenu';
import { closeMenu, toggleOpen } from '../../stores/slices/menuSlice';
import { useNavigate } from 'react-router-dom';

export default function SideMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeSession = useCallback(async () => {
    const exit = await confirmAction(
      '¿Está seguro que desea salir?',
      'Su sesión será cerrada'
    );
    if (exit) {
      dispatch(closeMenu());
      dispatch(logout());
    }
  }, [dispatch]);
  const menuState = useSelector((state) => state.menu);
  const menu = useMenu();

  const goToHome = () => {
    navigate('/');
    dispatch(toggleOpen());
  };

  return (
    <aside
      className={
        'side-menu d-flex flex-column h-100 ' + (menuState.isOpen ? 'open' : '')
      }
    >
      {!menuState.isOpen ? (
        <div className="text-white cursor-pointer my-2 d-none d-md-flex justify-content-center">
          <HamburguerMenu />
        </div>
      ) : (
        <div className="d-flex flex-column h-100">
          <SideMenuHeader />
          <div className="side-menu-items flex-grow-1">
            {menu.map((item) => {
              return (
                item.enabled &&
                item.showOnSideMenu && (
                  <SideMenuItem
                    item={item}
                    key={item.id}
                    active={menuState.active.id === item.id}
                  />
                )
              );
            })}
          </div>
          <div className="side-menu-footer">
            <div className="side-menu-home cursor-pointer text-primary">
              <span>
                <i className="fas fa-home fa-2x"></i>
              </span>
              <span
                onClick={goToHome}
                className="arvo-font side-menu-home-label"
              >
                Ir a Inicio
              </span>
            </div>
            <div
              className="side-menu-logout cursor-pointer"
              onClick={closeSession}
            >
              <span>
                <i className="fa fa-power-off fa-2x side-menu-logout-icon"></i>
              </span>
              <span className="arvo-font side-menu-logout-label">Salir</span>
            </div>
          </div>
        </div>
      )}
    </aside>
  );
}

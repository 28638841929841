import React from 'react';
import '../../styles/components/NewVersion.scss';
import { useDispatch } from 'react-redux';
import { showLoader } from '../../stores/slices/loaderSlice';
import { useServiceWorker } from './../../hooks/useServiceWorker';

export default function NewVersion() {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const dispatch = useDispatch();
  const handleUpdate = () => {
    dispatch(showLoader());
    reloadPage();
  };
  return (
    showReload &&
    waitingWorker && (
      <div className="new-version text-white">
        <div
          className="new-version-container scaleInOut font-bold"
          onClick={handleUpdate}
        >
          <span className="new-version-icon">
            <i className="fas fa-upload"></i>
          </span>
          <span className="new-version-text verdana-font">
            Nueva versión de la aplicación. Click aquí para obtener las últimas
            actualizaciones.
          </span>
        </div>
      </div>
    )
  );
}

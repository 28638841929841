import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BoardMainLink from '../BoardMainLink';
import NormatecaOption from '../NormatecaOption';
import BoardCalendar from './../BoardCalendar';
import './../../styles/components/Board.scss';
import BoardMessage from '../BoardMessage';
import useWindowDimensions from './../../hooks/useWindowDimensions';
import ConnectionIndicator from '../core/ConnectionIndicator';
import { useDispatch } from 'react-redux';
import { updateActiveMenu } from '../../stores/slices/menuSlice';
import useMenu from './../../hooks/useMenu';
import Config from './../../config/general-config';

export default function Board() {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const menu = useMenu();

  useEffect(() => {
    dispatch(updateActiveMenu({ label: 'Tablero de Gestión Escolar', id: '' }));
  }, [dispatch]);

  return (
    <Container className="container-board" fluid>
      <Row className="d-none d-md-flex">
        <Col xs={12} className="d-flex align-items-center">
          <h3 className="arvo-font text-primary d-flex my-4 justify-content-center align-items-center flex-grow-1">
            <span className="font-bold">Bienvenido(a) al </span>
            <span className="font-italic">Tablero de Gestión Escolar</span>
          </h3>
          <ConnectionIndicator />
        </Col>
      </Row>
      <Row className="mt-4 mt-md-0">
        <Col xs={12} md={8}>
          <Row>
            <Col xs={12}>
              <BoardMessage />
              {width < Config.MOBILE_WIDTH && <BoardCalendar />}
            </Col>
            {menu.map((item) => {
              return (
                item.enabled &&
                item.showOnBoard && (
                  <Col xs={12} md={6} key={item.id}>
                    <BoardMainLink {...item} />
                  </Col>
                )
              );
            })}
          </Row>
        </Col>
        <Col xs={12} md={4} className="calendar-section">
          {width > Config.MOBILE_WIDTH && <BoardCalendar />}
          <NormatecaOption />
        </Col>
      </Row>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import Procedure from './Procedure';
import '../styles/components/ProceduresList.scss';
import { Container, Row, Col } from 'react-bootstrap';
import ProceduresService from '../services/procedures.service';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../stores/slices/loaderSlice';
import { showToast, TYPE_ICON } from '../helpers/sweetAlert.helper';
import NoContent from './generic/NoContent';

export default function ProceduresList() {
  const [procedures, setProcedures] = useState([]);
  const idInstitution = useSelector((state) => state.institution.id);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProcedures = async () => {
      try {
        dispatch(showLoader());
        const procedures = await ProceduresService.getProcedures(idInstitution);
        if (procedures) {
          setProcedures(procedures);
        }
      } catch (e) {
        showToast(
          'Error',
          'No se han podido cargar los trámites de la institución',
          TYPE_ICON.ERROR
        );
      } finally {
        dispatch(hideLoader());
      }
    };
    fetchProcedures();
  }, [dispatch, idInstitution]);

  return (
    <div className="procedures-list mt-2 mt-lg-5">
      <Container>
        <NoContent
          thereAreContent={procedures.length > 0}
          title="La institución no ha configurado trámites y servicios aún"
          icon="fas fa-file-signature"
        >
          <Row>
            {procedures.map((procedure) => (
              <Col xs={6} md={4} lg={3} key={procedure.idServicio}>
                <Procedure procedure={procedure} />
              </Col>
            ))}
          </Row>
        </NoContent>
      </Container>
    </div>
  );
}
